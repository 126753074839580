@charset "UTF-8";
.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

html {
  overflow-x: hidden; }

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; }
  body::-webkit-scrollbar {
    width: 6px; }
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(88, 78, 68, 0.5);
    border-radius: 10px; }
  body::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px; }
  body::-webkit-scrollbar-thumb:hover {
    background: #000; }

ul {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin-bottom: 0;
  padding: 0; }

button {
  cursor: pointer; }

select {
  -webkit-appearance: none; }

input {
  -webkit-appearance: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

*:focus {
  outline: 0 !important; }

.form-control:focus {
  border-color: inherit !important;
  box-shadow: none !important; }

.modal-open {
  overflow: hidden !important; }

.modal-backdrop {
  opacity: 0; }

.hide {
  display: none !important; }

.main-container {
  max-width: 1020px;
  width: 100%;
  margin: auto;
  padding-right: 40px;
  padding-left: 40px; }
  @media (max-width: 540px) {
    .main-container {
      padding-right: 20px;
      padding-left: 20px; } }

.main-container-wide {
  max-width: 1200px; }

.main-btn {
  display: block;
  text-align: center;
  width: 200px;
  margin: auto;
  background-color: #45F6A6;
  color: black;
  padding: 6px 28px;
  font-weight: 500;
  border-radius: 52px;
  border: none; }

.login-container,
.wish-list-container,
.about-container,
.blog-list-container,
.contact-us-container,
.blog-details-container,
.admin-dashboard-container,
.interview-scheduler-container,
.subscription-manager-container,
.pricing-container {
  min-height: calc(100vh - 261px); }
  .login-container > .wish-list,
  .login-container > .about,
  .login-container > .blog-list,
  .login-container > .contact-us,
  .login-container > .interview-scheduler,
  .login-container > .subscription-manager,
  .login-container > .pricing,
  .wish-list-container > .wish-list,
  .wish-list-container > .about,
  .wish-list-container > .blog-list,
  .wish-list-container > .contact-us,
  .wish-list-container > .interview-scheduler,
  .wish-list-container > .subscription-manager,
  .wish-list-container > .pricing,
  .about-container > .wish-list,
  .about-container > .about,
  .about-container > .blog-list,
  .about-container > .contact-us,
  .about-container > .interview-scheduler,
  .about-container > .subscription-manager,
  .about-container > .pricing,
  .blog-list-container > .wish-list,
  .blog-list-container > .about,
  .blog-list-container > .blog-list,
  .blog-list-container > .contact-us,
  .blog-list-container > .interview-scheduler,
  .blog-list-container > .subscription-manager,
  .blog-list-container > .pricing,
  .contact-us-container > .wish-list,
  .contact-us-container > .about,
  .contact-us-container > .blog-list,
  .contact-us-container > .contact-us,
  .contact-us-container > .interview-scheduler,
  .contact-us-container > .subscription-manager,
  .contact-us-container > .pricing,
  .blog-details-container > .wish-list,
  .blog-details-container > .about,
  .blog-details-container > .blog-list,
  .blog-details-container > .contact-us,
  .blog-details-container > .interview-scheduler,
  .blog-details-container > .subscription-manager,
  .blog-details-container > .pricing,
  .admin-dashboard-container > .wish-list,
  .admin-dashboard-container > .about,
  .admin-dashboard-container > .blog-list,
  .admin-dashboard-container > .contact-us,
  .admin-dashboard-container > .interview-scheduler,
  .admin-dashboard-container > .subscription-manager,
  .admin-dashboard-container > .pricing,
  .interview-scheduler-container > .wish-list,
  .interview-scheduler-container > .about,
  .interview-scheduler-container > .blog-list,
  .interview-scheduler-container > .contact-us,
  .interview-scheduler-container > .interview-scheduler,
  .interview-scheduler-container > .subscription-manager,
  .interview-scheduler-container > .pricing,
  .subscription-manager-container > .wish-list,
  .subscription-manager-container > .about,
  .subscription-manager-container > .blog-list,
  .subscription-manager-container > .contact-us,
  .subscription-manager-container > .interview-scheduler,
  .subscription-manager-container > .subscription-manager,
  .subscription-manager-container > .pricing,
  .pricing-container > .wish-list,
  .pricing-container > .about,
  .pricing-container > .blog-list,
  .pricing-container > .contact-us,
  .pricing-container > .interview-scheduler,
  .pricing-container > .subscription-manager,
  .pricing-container > .pricing {
    min-height: calc(100vh - 551px); }

.graduate-dashboard,
.graduate-onboarding {
  min-height: calc(100vh - 286px); }

.cookieConsent {
  width: calc(100% - 40px) !important;
  left: 20px !important;
  bottom: 18px !important;
  border-radius: 25px !important;
  padding: 15px; }
  .cookieConsent > div {
    margin-bottom: 15px; }
  .cookieConsent button {
    background-color: #45F6A6 !important;
    margin: 10px 0 !important;
    border-radius: 30px !important;
    height: 100% !important;
    padding: 15px 24px !important;
    display: inline-block !important;
    color: black !important; }
  @media (max-width: 700px) {
    .cookieConsent {
      display: block !important;
      border-radius: 12px !important; }
      .cookieConsent button {
        width: 100%;
        border-radius: 0 0 12px 12px !important; } }

button.underline-on-hover:hover,
a.underline-on-hover:hover {
  text-decoration: underline; }

@font-face {
  font-family: "Conv_VisbyCF-Heavy";
  src: url("./fonts/VisbyCF-Heavy/VisbyCF-Heavy.eot");
  src: local("☺"), url("./fonts/VisbyCF-Heavy/VisbyCF-Heavy.woff") format("woff"), url("./fonts/VisbyCF-Heavy/VisbyCF-Heavy.ttf") format("truetype"), url("./fonts/VisbyCF-Heavy/VisbyCF-Heavy.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

* {
  font-family: "Poppins", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Conv_VisbyCF-Heavy", Sans-Serif !important; }

.toasts-container {
  transform: none;
  left: unset !important;
  right: 10px !important;
  transform: none !important;
  flex-direction: row !important;
  color: #fff;
  font-weight: 600;
  border: none !important; }
  .toasts-container > div {
    box-shadow: none !important;
    border: none !important; }

.back_search_btn_wrap .back-to-searchs {
  position: fixed;
  top: calc(50% - 20px);
  left: 30px;
  z-index: 10;
  width: 40px;
  height: 40px;
  background: #45f6a6;
  border-radius: 50%;
  color: black;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 640px) {
    .back_search_btn_wrap .back-to-searchs {
      left: 15px; } }
  .back_search_btn_wrap .back-to-searchs.back-to-search-next {
    left: inherit;
    right: 30px; }
    @media (max-width: 640px) {
      .back_search_btn_wrap .back-to-searchs.back-to-search-next {
        right: 15px; } }

.quick-signup-success {
  text-align: center; }
  .quick-signup-success .modal-content p {
    font-size: 0.925rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    word-break: break-word; }
    .quick-signup-success .modal-content p a {
      color: #12d67d; }
  .quick-signup-success .modal-content h3 {
    font-weight: 500;
    color: #12d67d;
    font-size: 20px;
    margin-bottom: 20px; }
  .quick-signup-success .modal-content button {
    background: #45F6A6;
    border: 1px solid #45F6A6;
    color: #000032;
    border-radius: 25px;
    padding: 8px 30px;
    font-size: 0.875rem;
    line-height: 1.4rem;
    font-weight: 500; }

.quick_sector_apply {
  padding: 25px;
  border-radius: 6px;
  max-height: 620px;
  overflow-y: auto; }
  .quick_sector_apply h5 {
    margin-bottom: 5px;
    font-size: 1.5rem;
    line-height: 2.1rem;
    font-weight: 700; }
    @media (max-width: 640px) {
      .quick_sector_apply h5 {
        font-size: 1.2rem;
        line-height: 1.7rem; } }
  .quick_sector_apply > p {
    font-size: 1.2rem;
    font-weight: 500; }
  .quick_sector_apply h6 {
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 600;
    margin-bottom: 20px; }
  .quick_sector_apply .tag_wrap {
    position: relative;
    margin: 0 -5px; }
    .quick_sector_apply .tag_wrap .see_more_btn {
      position: absolute;
      bottom: calc(100% + 20px);
      right: 5px;
      border: none;
      font-size: 0.724rem;
      font-weight: 600;
      color: #10d47c;
      z-index: 1;
      background: transparent;
      padding: 0;
      border-radius: 3px; }
    .quick_sector_apply .tag_wrap span.tag {
      margin: 5px;
      padding: 6px 12px;
      border-radius: 25px;
      font-size: 13px;
      font-weight: 500;
      word-break: break-word;
      cursor: pointer;
      display: inline-block; }
  .quick_sector_apply .wrap_all_tagss {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px; }
    .quick_sector_apply .wrap_all_tagss .tags {
      min-width: 120px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
      position: relative;
      border: 1px solid #eee; }
      .quick_sector_apply .wrap_all_tagss .tags::before {
        position: absolute;
        width: 10px;
        height: 7px;
        border: 2px solid transparent;
        content: "";
        border-bottom-color: #000032;
        border-left-color: #000032;
        top: 11px;
        right: 15px;
        transform: rotate(-45deg);
        z-index: 1;
        opacity: 0; }
      .quick_sector_apply .wrap_all_tagss .tags::after {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 1px solid #01f895;
        background-color: #01f895;
        content: "";
        top: 7px;
        right: 10px;
        z-index: 0;
        opacity: 0;
        border-radius: 3px; }
      .quick_sector_apply .wrap_all_tagss .tags img {
        max-width: 100%;
        width: 40px;
        height: 40px;
        object-fit: contain; }
      .quick_sector_apply .wrap_all_tagss .tags:hover {
        background-color: #45f6a612 !important; }
      .quick_sector_apply .wrap_all_tagss .tags span {
        display: inline-block;
        padding: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .quick_sector_apply .wrap_all_tagss .tags.green:hover {
        border-color: #45f7a6;
        background-color: #45f6a612 !important; }
      .quick_sector_apply .wrap_all_tagss .tags.green svg.selected {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 30px;
        height: 30px;
        color: #45f7a6;
        display: inline-block;
        background: rgba(0, 0, 0, 0);
        fill: #45f7a6; }
        .quick_sector_apply .wrap_all_tagss .tags.green svg.selected path {
          fill: #45f7a6; }
  .quick_sector_apply .bottom_action_btn {
    text-align: right;
    margin-top: 20px; }
    .quick_sector_apply .bottom_action_btn button.submit_btn {
      background: #45F6A6;
      padding: 10px 35px;
      border-radius: 25px;
      font-size: 0.875rem;
      font-weight: 600;
      border: none;
      outline: none; }

.modal-dialog.quick-signup-sector {
  max-width: 60%; }
  .modal-dialog.quick-signup-sector .remote_work_check label.control.control--radio {
    color: #000032;
    font-size: 0.875rem;
    font-weight: 400;
    display: inline-block; }
    .modal-dialog.quick-signup-sector .remote_work_check label.control.control--radio .control__indicator {
      top: 0;
      border-radius: 2px;
      background: #fff;
      border: 1px solid #b0afaf; }
      .modal-dialog.quick-signup-sector .remote_work_check label.control.control--radio .control__indicator:after {
        top: 0px; }
    .modal-dialog.quick-signup-sector .remote_work_check label.control.control--radio input:checked ~ .control__indicator {
      background: #bd99ff; }
  .modal-dialog.quick-signup-sector .select_tags_wrap {
    margin-top: 20px;
    margin-bottom: 30px; }
    .modal-dialog.quick-signup-sector .select_tags_wrap .form-group-wrap p {
      margin-bottom: 0;
      color: #000032;
      font-size: 0.875rem;
      font-weight: 400; }
    .modal-dialog.quick-signup-sector .select_tags_wrap .form-group-wrap .form-group {
      width: 100%; }
    .modal-dialog.quick-signup-sector .select_tags_wrap .form-group-wrap .tag_wraps {
      position: relative; }
      .modal-dialog.quick-signup-sector .select_tags_wrap .form-group-wrap .tag_wraps::before {
        position: absolute;
        top: calc(50% - 5px);
        right: 15px;
        width: 10px;
        height: 10px;
        border: 2px solid #000032;
        border-radius: 2px;
        border-top: transparent;
        border-right: transparent;
        content: "";
        transform: rotate(-45deg);
        pointer-events: none; }
    .modal-dialog.quick-signup-sector .select_tags_wrap .form-group-wrap select {
      width: 100%;
      height: 40px;
      padding: 10px 15px;
      margin-top: 5px;
      border: 1px solid #d6d2d2;
      border-radius: 6px;
      color: #000032;
      font-size: 0.755rem;
      font-weight: 400; }
    @media only screen and (max-width: 575px) {
      .modal-dialog.quick-signup-sector .select_tags_wrap .form-group-wrap {
        flex-direction: column; } }
  @media (max-width: 991px) {
    .modal-dialog.quick-signup-sector {
      max-width: 80%; } }
  @media (max-width: 640px) {
    .modal-dialog.quick-signup-sector {
      max-width: 90%; } }

.border-line-active {
  margin: 15px 0;
  border: 1px solid #00003213;
  position: relative; }
  .border-line-active::before {
    position: absolute;
    content: "";
    width: 100px;
    height: 3px;
    top: calc(50% - 1.5px);
    left: 0;
    background: #45F6A6; }

.quickredirect_link {
  padding: 35px;
  position: relative; }
  .quickredirect_link p {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    margin-bottom: 0; }
    .quickredirect_link p span {
      font-weight: 400;
      color: #12d67d;
      display: block;
      margin-top: 5px; }
  .quickredirect_link::before, .quickredirect_link:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    border-radius: 3px 3px 0 0;
    content: "";
    background-color: #45F6A6;
    opacity: 0.6; }
  .quickredirect_link::after {
    background-color: #10d47c;
    animation: mymove_new 2s infinite;
    opacity: 1; }

@keyframes mymove_new {
  from {
    width: 0%; }
  to {
    width: 100%; } }

.home-page-gradbay .blog-preview {
  padding-top: 1rem; }

.carrer_hub_model form > div {
  margin-bottom: 10px; }
  .carrer_hub_model form > div input {
    -webkit-appearance: revert !important;
    appearance: revert !important; }

.carrer_hub_model form .url_wrap {
  position: relative; }
  .carrer_hub_model form .url_wrap svg {
    position: absolute;
    top: calc(50% - 13px);
    left: 1rem; }
  .carrer_hub_model form .url_wrap input {
    padding-left: 40px !important;
    font-size: 0.875rem !important;
    min-height: 40px; }

.carrer_hub_model form .file-input {
  position: relative;
  clear: both;
  min-height: 45px;
  margin-bottom: 15px; }
  .carrer_hub_model form .file-input input {
    padding: 4px 18px 4px 115px;
    height: 38px;
    width: 90%;
    border-radius: 28px;
    background-color: transparent;
    color: #212121;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 28px;
    background-clip: padding-box;
    font-weight: 400;
    display: block;
    position: absolute;
    z-index: 11;
    opacity: 1;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #eeecec; }
  .carrer_hub_model form .file-input label {
    position: relative;
    top: 50%;
    border-radius: 25px 0px 0px 25px;
    background-color: #424242;
    color: rgba(255, 255, 255, 0.87);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 16px;
    width: 112px;
    padding: 11px 10px;
    text-align: center;
    pointer-events: none;
    z-index: 101;
    cursor: pointer;
    margin-bottom: 0px; }
  .carrer_hub_model form .file-input #description-dummy {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 5px 120px 5px 18px; }
  .carrer_hub_model form .file-input label.browses {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 25px 25px 0;
    font-weight: 500; }

.booster_strip {
  padding-bottom: 36px; }
  .booster_strip .boost_profile_wrap {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #0c1a30 !important;
    padding: 5px 0;
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9911;
    transition: transform 200ms ease-in-out !important;
    margin-bottom: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1); }
    .booster_strip .boost_profile_wrap span {
      font-size: 0.875rem;
      color: #fff;
      font-weight: 300; }
      .booster_strip .boost_profile_wrap span .CircularProgressbar .CircularProgressbar-text {
        font-weight: 600;
        fill: #fff; }
      .booster_strip .boost_profile_wrap span b {
        color: #ffb5b5; }
    .booster_strip .boost_profile_wrap .add_cta_boost {
      font-size: 0.675rem;
      padding: 4px 10px;
      color: #ffb5b5;
      text-transform: uppercase;
      background: transparent;
      border-radius: 25px;
      margin-left: 20px;
      letter-spacing: 1px;
      font-weight: 600;
      text-decoration: none;
      border: 2px solid #ffb5b5; }
    @media only screen and (max-width: 580px) {
      .booster_strip .boost_profile_wrap {
        flex-direction: column;
        gap: 10px; }
        .booster_strip .boost_profile_wrap span {
          text-align: center;
          padding: 0 10px;
          display: block; } }
    .booster_strip .boost_profile_wrap .close {
      font-weight: 100;
      opacity: 1;
      cursor: pointer; }
      .booster_strip .boost_profile_wrap .close span {
        font-size: 23px;
        position: relative;
        bottom: 4px; }
      @media only screen and (max-width: 991px) {
        .booster_strip .boost_profile_wrap .close {
          right: inherit;
          left: 0; } }
    @media only screen and (max-width: 991px) {
      .booster_strip .boost_profile_wrap {
        position: fixed !important;
        bottom: 0;
        left: 0;
        padding-left: 40px; } }
  @media only screen and (max-width: 580px) {
    .booster_strip {
      padding-bottom: 90px; } }

.pagination-wrapper.job_list_paginate .pagination li.first_page, .pagination-wrapper.job_list_paginate .pagination li.last_page {
  background: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0; }
  .pagination-wrapper.job_list_paginate .pagination li.first_page a, .pagination-wrapper.job_list_paginate .pagination li.last_page a {
    transform: rotate(0deg) !important;
    position: initial !important; }

@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
  .modal-open .modal.show {
    display: flex !important;
    justify-content: center; }
  .modal-dialog {
    position: absolute !important; } }

@media only screen and (min-width: 320px) and (max-width: 1023px) and (min-aspect-ratio: 13 / 9) and (orientation: landscape) {
  .modal-open .modal.show {
    display: flex !important; } }

@media only screen and (max-width: 991px) {
  body .job-closed-modal {
    display: flex;
    align-items: center; }
    body .job-closed-modal .content {
      padding: 20px 10px; }
      body .job-closed-modal .content h4 {
        font-size: 1.2rem; } }

body .job-closed-modal .spin_loader {
  display: inline-block;
  animation-name: spin_loader;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

body .job-closed-modal .body {
  max-height: 650px;
  overflow-y: auto; }
  body .job-closed-modal .body::-webkit-scrollbar {
    width: 2px; }
  body .job-closed-modal .body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(88, 78, 68, 0.5);
    border-radius: 10px; }
  body .job-closed-modal .body::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px; }
  body .job-closed-modal .body::-webkit-scrollbar-thumb:hover {
    background: #000; }
  body .job-closed-modal .body .empty-icons svg, body .job-closed-modal .body .filled-icons svg {
    margin-right: 10px; }
  body .job-closed-modal .body p b {
    font-weight: 500;
    font-size: 0.875rem; }
  body .job-closed-modal .body .mt-3.reason label {
    font-size: 0.875rem;
    font-weight: 300; }
  body .job-closed-modal .body textarea {
    width: 100%;
    outline: none;
    font-size: 0.775rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 15px;
    color: #212529;
    height: 100px;
    margin-bottom: 20px; }
  body .job-closed-modal .body .status-msg {
    color: #ff0606;
    margin-left: 4px;
    font-weight: 400;
    font-size: 0.875rem; }
  @media only screen and (max-width: 991px) {
    body .job-closed-modal .body {
      max-height: 500px; } }

body .job-closed-modal .terms_condition_wrap input {
  display: none;
  font-size: 0.875rem; }
  body .job-closed-modal .terms_condition_wrap input ~ label {
    display: inline-block;
    padding-left: 30px;
    position: relative;
    cursor: pointer; }
    body .job-closed-modal .terms_condition_wrap input ~ label::before {
      position: absolute;
      top: calc(50% - 10px);
      content: '';
      width: 20px;
      height: 20px;
      left: 0;
      border: 1px solid #ccc;
      border-radius: 50%; }
    body .job-closed-modal .terms_condition_wrap input ~ label a {
      color: #45F6A6DE;
      text-decoration: none; }
  body .job-closed-modal .terms_condition_wrap input:checked ~ label::before {
    border-color: #45F6A6DE; }
  body .job-closed-modal .terms_condition_wrap input:checked ~ label::after {
    position: absolute;
    top: calc(50% - 5px);
    content: '';
    width: 10px;
    height: 10px;
    left: 5px;
    background: #45F6A6DE;
    border-radius: 50%; }

@keyframes spin_loader {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.container_full {
  max-width: 1600px; }
