.home_new_slider .slide_image img {
  max-width: 100%;
  width: 100%; }

.home_new_slider .slide_image .whole_link_banner {
  position: absolute;
  bottom: 15.5%;
  left: 6.8%;
  width: 23%;
  height: 15%;
  z-index: 1;
  background: transparent;
  cursor: pointer;
  border-radius: 50px; }
  @media only screen and (max-width: 1024px) {
    .home_new_slider .slide_image .whole_link_banner {
      bottom: 16.8%; } }
  @media only screen and (max-width: 640px) {
    .home_new_slider .slide_image .whole_link_banner {
      bottom: 17.8%; } }

.home_new_slider .new_slider_wrap {
  display: flex;
  align-items: center;
  max-width: 1460px;
  margin: 0 auto;
  min-height: 680px; }
  .home_new_slider .new_slider_wrap .banner_content_wrap {
    flex: 2; }
    .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl {
      font-size: 5rem;
      line-height: 1.1; }
      .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p {
        font-size: 2rem;
        line-height: 2.6rem;
        font-weight: 500; }
      @media only screen and (max-width: 1440px) {
        .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl {
          font-size: 4rem; }
          .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p {
            font-size: 1.5rem; } }
      @media only screen and (max-width: 1199px) {
        .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl {
          font-size: 3.2rem; }
          .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p {
            font-size: 1.2rem; } }
      @media only screen and (max-width: 991px) {
        .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl {
          font-size: 2.1rem;
          text-align: center; }
          .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p {
            font-size: 1rem;
            margin: 0;
            text-align: center;
            line-height: 1.4rem; }
            .home_new_slider .new_slider_wrap .banner_content_wrap h1.display_xl ~ p br {
              display: none; } }
    @media only screen and (max-width: 991px) {
      .home_new_slider .new_slider_wrap .banner_content_wrap {
        text-align: center; } }
  .home_new_slider .new_slider_wrap figure {
    flex: 1; }
    .home_new_slider .new_slider_wrap figure img {
      max-width: 100%; }
  @media only screen and (max-width: 1600px) {
    .home_new_slider .new_slider_wrap {
      max-width: 90%; } }
  @media only screen and (max-width: 991px) {
    .home_new_slider .new_slider_wrap {
      flex-direction: column-reverse;
      padding: 30px 0;
      min-height: 700px; }
      .home_new_slider .new_slider_wrap figure img {
        max-width: 400px; } }
    @media only screen and (max-width: 991px) and (max-width: 640px) {
      .home_new_slider .new_slider_wrap figure img {
        max-width: 300px; } }

@media only screen and (max-width: 800px) {
  .home_page_new .jobtype-page {
    margin-top: 0;
    padding-top: 50px; } }

.companies_slider_wrap {
  padding-top: 30px;
  background: #F5F5F5; }
  .companies_slider_wrap .companies-slider {
    border-radius: 0; }
  .companies_slider_wrap h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center; }

.gradbay_can_help {
  padding: 30px 0;
  background: #c3ffff; }
  .gradbay_can_help .section_heading {
    text-align: center;
    color: #333333;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 40px; }
  .gradbay_can_help .can_help_inner > div {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px; }
    .gradbay_can_help .can_help_inner > div figure {
      margin: 0;
      padding: 0;
      margin-right: 15px; }
      .gradbay_can_help .can_help_inner > div figure img {
        width: 60px;
        height: 60px;
        object-fit: contain; }
        @media only screen and (max-width: 640px) {
          .gradbay_can_help .can_help_inner > div figure img {
            width: 40px;
            height: 40px; } }
    .gradbay_can_help .can_help_inner > div p {
      font-size: 1rem;
      line-height: 1.4rem;
      color: #222222;
      font-weight: 500;
      margin-bottom: 0; }
    @media only screen and (max-width: 991px) {
      .gradbay_can_help .can_help_inner > div {
        align-items: center; }
        .gradbay_can_help .can_help_inner > div p {
          font-size: 0.875rem;
          line-height: 1.3rem; } }

.home_new_slider_wrapper .slick-track .slick-slide {
  height: 100%; }
  .home_new_slider_wrapper .slick-track .slick-slide > div {
    height: 100%; }
    .home_new_slider_wrapper .slick-track .slick-slide > div .home_new_slider {
      height: 100%; }
    .home_new_slider_wrapper .slick-track .slick-slide > div .banner_slide_btn {
      padding: 20px 20px;
      font-size: 1.3rem;
      line-height: 1.8rem;
      color: #000;
      background: #45f7a6;
      border-radius: 50px;
      outline: none;
      box-shadow: none;
      display: inline-block;
      margin-top: 25px;
      text-align: center;
      min-width: 240px;
      letter-spacing: 1px; }
      @media only screen and (max-width: 640px) {
        .home_new_slider_wrapper .slick-track .slick-slide > div .banner_slide_btn {
          min-width: 200px;
          padding: 15px 16px;
          font-size: 1rem;
          line-height: 1.3rem; } }
    .home_new_slider_wrapper .slick-track .slick-slide > div .first_slide {
      background: #c3ffff; }
      .home_new_slider_wrapper .slick-track .slick-slide > div .first_slide .banner_content_wrap span {
        color: #45f7a6;
        font-weight: bold;
        position: relative;
        display: inline-block;
        line-height: 20px;
        padding: 7px;
        z-index: 1;
        min-width: 60px;
        text-align: center; }
        @media only screen and (min-width: 1200px) {
          .home_new_slider_wrapper .slick-track .slick-slide > div .first_slide .banner_content_wrap span {
            min-width: 90px !important; } }
        .home_new_slider_wrapper .slick-track .slick-slide > div .first_slide .banner_content_wrap span::before {
          content: "";
          background: #bd99ff;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          transform: rotate(-4deg) translateY(-1px); }
    .home_new_slider_wrapper .slick-track .slick-slide > div .second_slide {
      background: #ffb4b5; }
      .home_new_slider_wrapper .slick-track .slick-slide > div .second_slide .banner_content_wrap span {
        color: #bd99ff;
        font-weight: bold;
        position: relative;
        display: inline-block;
        line-height: 20px;
        padding: 7px 10px;
        z-index: 1;
        min-width: 70px;
        text-align: center; }
        .home_new_slider_wrapper .slick-track .slick-slide > div .second_slide .banner_content_wrap span::before {
          content: "";
          background: #45f7a6;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          transform: rotate(-4deg) translateY(-1px); }
    .home_new_slider_wrapper .slick-track .slick-slide > div .three_slide {
      background: #45f7a6; }
      .home_new_slider_wrapper .slick-track .slick-slide > div .three_slide .banner_content_wrap span {
        color: #45f7a6;
        font-weight: bold;
        position: relative;
        display: inline-block;
        line-height: 20px;
        padding: 7px 10px;
        z-index: 1;
        min-width: 70px;
        text-align: center; }
        .home_new_slider_wrapper .slick-track .slick-slide > div .three_slide .banner_content_wrap span::before {
          content: "";
          background: #bd99ff;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          transform: rotate(-4deg) translateY(-1px); }
      .home_new_slider_wrapper .slick-track .slick-slide > div .three_slide .banner_slide_btn {
        background: #bd99ff; }
    .home_new_slider_wrapper .slick-track .slick-slide > div .fourth_slide {
      background: #bd99ff; }
      .home_new_slider_wrapper .slick-track .slick-slide > div .fourth_slide .banner_content_wrap span {
        color: #bd99ff;
        font-weight: bold;
        position: relative;
        display: inline-block;
        line-height: 20px;
        padding: 7px 10px;
        z-index: 1;
        min-width: 70px;
        text-align: center; }
        .home_new_slider_wrapper .slick-track .slick-slide > div .fourth_slide .banner_content_wrap span::before {
          content: "";
          background: #45f7a6;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          transform: rotate(-4deg) translateY(-1px); }
