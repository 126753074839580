@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Poppins", sans-serif; }

.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.global-search {
  background-color: purple;
  position: relative;
  /*background: linear-gradient(to right, #7814b2 0%,#43aba3 100%);*/
  background: #ffb3b5;
  min-height: 562px;
  overflow: hidden; }
  .global-search .additional-link {
    order: 0;
    background-color: #ffd956;
    border-radius: 52px;
    color: black;
    padding: 6px 20px;
    font-weight: 500;
    margin-left: 48px;
    margin-bottom: 40px;
    display: inline-block;
    float: right; }
  .global-search .skill-level-picker {
    z-index: 1001;
    background: transparent; }
  .global-search .reviews {
    align-items: flex-end;
    display: flex;
    position: relative; }
    .global-search .reviews > div {
      display: flex; }
      .global-search .reviews > div .details {
        position: absolute;
        right: 37px;
        top: 50%;
        transform: translateY(30px);
        z-index: 2; }
        .global-search .reviews > div .details .banner-user-review {
          border-radius: 14.4px;
          padding: 7px 10px;
          background-color: #ffffff;
          box-shadow: 0 22px 58px 0 rgba(41, 40, 0, 0.1);
          min-width: 140px; }
          .global-search .reviews > div .details .banner-user-review h3 {
            color: #333333;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 28.8px;
            margin: 0; }
          .global-search .reviews > div .details .banner-user-review p {
            color: rgba(51, 51, 51, 0.5);
            font-size: 10px;
            letter-spacing: 0;
            line-height: 13px;
            margin: 0; }
          .global-search .reviews > div .details .banner-user-review .stars svg {
            width: 16px;
            height: 16px;
            margin-right: 2px; }
      .global-search .reviews > div .person {
        width: 260px;
        position: absolute;
        bottom: -60px;
        text-align: center; }
        .global-search .reviews > div .person img {
          max-width: 100%;
          max-height: 360px; }
        .global-search .reviews > div .person:after {
          position: absolute;
          content: "";
          height: 394px;
          width: 394px;
          background-color: #c3ffff;
          border-radius: 50%;
          bottom: 10px;
          left: -30%;
          z-index: -1;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.45); }
    @media only screen and (max-width: 991px) {
      .global-search .reviews {
        display: none; } }
  .global-search .cv-search-toggle {
    display: inline-block;
    float: right; }
    .global-search .cv-search-toggle p {
      display: inline-block;
      margin: 0;
      color: white;
      font-weight: 600; }
      .global-search .cv-search-toggle p:last-child {
        padding-left: 40px; }
    .global-search .cv-search-toggle .toggle {
      display: inline-block;
      position: relative;
      top: 10px;
      left: 20px; }
      .global-search .cv-search-toggle .toggle .tgl-btn {
        background: transparent;
        border: 3px solid white !important;
        width: 56px; }
        .global-search .cv-search-toggle .toggle .tgl-btn:after {
          background: white !important; }
      .global-search .cv-search-toggle .toggle .tgl-btn:after {
        width: 22px; }
      .global-search .cv-search-toggle .toggle .tgl-btn:after {
        background: white; }
      .global-search .cv-search-toggle .toggle label {
        margin: 0; }
  .global-search > .main {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 40px;
    z-index: 10;
    position: relative;
    top: 0;
    left: 0;
    margin: auto;
    padding-top: 10em;
    padding-bottom: 4em;
    transform: none;
    margin: auto; }
    .global-search > .main h1,
    .global-search > .main .visible,
    .global-search > .main .advanced-filters-toggle,
    .global-search > .main .advanced-filters {
      max-width: 960px !important;
      margin: auto; }
    .global-search > .main h3.sub-filter {
      margin-bottom: 0 !important;
      margin-left: 4px;
      font-size: 1.2em !important;
      font-weight: 700;
      margin-top: 20px; }
    .global-search > .main .featured-tags.dropdown {
      max-width: 1200px; }
      .global-search > .main .featured-tags.dropdown svg {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer; }
  .global-search .main-container .back-button {
    border: 0;
    background-color: white;
    border-radius: 52px;
    color: black;
    padding: 6px 20px;
    font-weight: 500;
    margin-left: 48px;
    margin-bottom: 40px; }
    .global-search .main-container .back-button:hover {
      color: black !important; }
  .global-search .job-search-container {
    position: relative; }
    .global-search .job-search-container h1 {
      color: #000032;
      font-size: 54px;
      font-weight: 700;
      letter-spacing: -0.5px;
      line-height: 60px;
      max-width: 460px !important;
      margin: 0; }
      @media only screen and (max-width: 991px) {
        .global-search .job-search-container h1 {
          max-width: 100% !important;
          text-align: center;
          font-size: 38px;
          line-height: 48px; } }
      @media only screen and (max-width: 767px) {
        .global-search .job-search-container h1 {
          font-size: 30px;
          line-height: 40px; } }
      @media only screen and (max-width: 575px) {
        .global-search .job-search-container h1 {
          font-size: 25px;
          line-height: 35px; } }
    .global-search .job-search-container h2 {
      color: #000032 !important;
      font-size: 21px;
      margin-top: 30px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 26px;
      max-width: 500px; }
      @media only screen and (max-width: 991px) {
        .global-search .job-search-container h2 {
          max-width: 100%;
          text-align: center; } }
      @media only screen and (max-width: 768px) {
        .global-search .job-search-container h2 {
          font-size: 16px; } }
      @media only screen and (max-width: 575px) {
        .global-search .job-search-container h2 {
          font-size: 14px;
          line-height: 24px; } }
    .global-search .job-search-container .advanced-filters-toggle {
      text-align: center; }
      .global-search .job-search-container .advanced-filters-toggle button {
        background-color: #45F6A6;
        border: none;
        border-radius: 52px;
        padding: 8px 16px;
        height: 50px;
        font-weight: 500;
        float: left;
        font-size: 13px;
        margin-right: 12px; }
        .global-search .job-search-container .advanced-filters-toggle button.clear-all-filters {
          background-color: #db3951;
          color: white;
          margin-left: 16px; }
    .global-search .job-search-container .advanced-filters {
      margin-top: 24px;
      background-color: white;
      border-radius: 10px;
      padding: 30px;
      position: relative; }
      .global-search .job-search-container .advanced-filters .date-range-picker-custom .date-field {
        position: relative; }
        .global-search .job-search-container .advanced-filters .date-range-picker-custom .date-field span.icon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          top: 40%;
          right: 20px;
          cursor: pointer; }
      .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker {
        width: 100%;
        height: 100%; }
        .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker .DateRangePickerInput {
          margin-top: 0;
          width: 100%;
          background: none;
          border: 1px solid #ccc;
          height: 48px;
          padding: 7px 6px;
          padding-top: 10px; }
          .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker .DateRangePickerInput .DateInput {
            height: 100%; }
          .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker .DateRangePickerInput .DateInput_input {
            background: transparent;
            color: #838383;
            font-size: 15px;
            letter-spacing: 0.12px;
            line-height: 19px; }
          .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker .DateRangePickerInput .CalendarDay__selected,
          .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker .DateRangePickerInput .CalendarDay__selected:active,
          .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker .DateRangePickerInput .CalendarDay__selected:hover {
            background: #6eed9c;
            border-color: #6eed9c; }
        .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker .DateRangePickerInput_arrow_1 {
          width: 15px;
          height: 1px;
          background: rgba(131, 131, 131, 0.5); }
          .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker .DateRangePickerInput_arrow_1 .DateRangePickerInput_arrow_svg_1 {
            display: none; }
        .global-search .job-search-container .advanced-filters .date-range-picker-custom .DateRangePicker svg {
          top: -2px;
          position: relative;
          margin: 0 8px; }
      .global-search .job-search-container .advanced-filters .salary .salary-options {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 32px; }
        .global-search .job-search-container .advanced-filters .salary .salary-options .range {
          flex-basis: 50%;
          border: 1px solid #e0e0e0;
          padding: 12px 20px;
          border-radius: 52px;
          margin-right: 10px; }
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__label--min,
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__label--max {
            display: none; }
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__label--value {
            top: -50px;
            color: #333;
            font-weight: 600; }
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__track--active,
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__slider {
            background: #45F6A6;
            border: none; }
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__track {
            background: #bcbcbf; }
            .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__track.input-range__track--active {
              background: #3cf39b; }
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__label-container {
            color: #2a4773;
            font-size: 10px; }
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__slider-container .input-range__label-container {
            left: -75%; }
          .global-search .job-search-container .advanced-filters .salary .salary-options .range .input-range__slider-container + .input-range__slider-container .input-range__label-container {
            left: 15%; }
        .global-search .job-search-container .advanced-filters .salary .salary-options .period {
          flex-basis: 50%;
          display: flex;
          flex-wrap: nowrap;
          padding: 4px;
          margin-left: 10px;
          border: 1px solid #cfcfcf;
          border-radius: 20px; }
          .global-search .job-search-container .advanced-filters .salary .salary-options .period > div {
            flex-basis: 25%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            border-radius: 20px; }
            .global-search .job-search-container .advanced-filters .salary .salary-options .period > div[data-selected="true"] {
              background-color: #651ba0;
              color: white; }
        @media only screen and (max-width: 767px) {
          .global-search .job-search-container .advanced-filters .salary .salary-options {
            flex-direction: column; }
            .global-search .job-search-container .advanced-filters .salary .salary-options .range {
              margin-right: 0;
              margin-bottom: 20px; }
            .global-search .job-search-container .advanced-filters .salary .salary-options .period {
              margin: 0; }
              .global-search .job-search-container .advanced-filters .salary .salary-options .period > div {
                line-height: 34px; } }
      .global-search .job-search-container .advanced-filters > h3 {
        font-weight: 700;
        font-size: 1.4em; }
      .global-search .job-search-container .advanced-filters h1 {
        font-weight: 700;
        color: black;
        text-align: left;
        margin: 0 0 24px 6px !important;
        font-size: 1.2em;
        text-transform: uppercase;
        color: black;
        display: block !important; }
      .global-search .job-search-container .advanced-filters .ReactTags__tagInput {
        margin-right: 12px; }
      .global-search .job-search-container .advanced-filters .ReactTags__tag {
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 15px; }
      .global-search .job-search-container .advanced-filters > div {
        min-height: 60px; }
        .global-search .job-search-container .advanced-filters > div h5 {
          color: #222222;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 16px;
          margin-top: 20px; }
        .global-search .job-search-container .advanced-filters > div.green-tags .ReactTags__tag {
          background-color: #45F6A6 !important;
          margin: 4px !important; }
        .global-search .job-search-container .advanced-filters > div.purple-tags .ReactTags__tag {
          background-color: #651ba0 !important;
          margin: 4px !important; }
        .global-search .job-search-container .advanced-filters > div.red-tags .ReactTags__tag {
          background-color: #651ba0 !important;
          margin: 4px !important; }
        .global-search .job-search-container .advanced-filters > div h3 {
          display: inline-block;
          margin: 20px 0 10px !important;
          width: 100%;
          color: #222222;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: 0;
          line-height: 22px; }
        .global-search .job-search-container .advanced-filters > div .alt-tags {
          margin-bottom: -16px; }
          .global-search .job-search-container .advanced-filters > div .alt-tags .taga {
            background-color: #f5f5f5;
            padding: 10px 15px;
            display: inline-block;
            margin: 0 16px 16px 0;
            cursor: pointer;
            color: #222222;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            border-radius: 21px; }
            .global-search .job-search-container .advanced-filters > div .alt-tags .taga[data-selected="true"] {
              background-color: #651ba0;
              color: white; }
        .global-search .job-search-container .advanced-filters > div .custom-selectbox {
          position: relative;
          cursor: pointer; }
          .global-search .job-search-container .advanced-filters > div .custom-selectbox .ReactTags__tags {
            border: none;
            padding: 0; }
            .global-search .job-search-container .advanced-filters > div .custom-selectbox .ReactTags__tags .ReactTags__selected {
              margin-top: 0px;
              height: auto;
              border: 1px solid #ccc;
              min-height: 44px;
              padding-right: 0;
              border-radius: 22px;
              display: inline-block;
              width: 100%;
              padding-right: 50px; }
              .global-search .job-search-container .advanced-filters > div .custom-selectbox .ReactTags__tags .ReactTags__selected .ReactTags__tag {
                padding: 10px 15px;
                float: left; }
                .global-search .job-search-container .advanced-filters > div .custom-selectbox .ReactTags__tags .ReactTags__selected .ReactTags__tag .ReactTags__remove {
                  top: 1px;
                  color: #fff;
                  text-decoration: none; }
              .global-search .job-search-container .advanced-filters > div .custom-selectbox .ReactTags__tags .ReactTags__selected .ReactTags__tagInput {
                margin-right: 0px;
                height: 44px;
                display: flex;
                align-items: center; }
                .global-search .job-search-container .advanced-filters > div .custom-selectbox .ReactTags__tags .ReactTags__selected .ReactTags__tagInput .ReactTags__suggestions {
                  width: 100%;
                  border-radius: 16px;
                  background-color: #ffffff;
                  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.23);
                  max-height: 262px;
                  overflow-y: auto; }
              .global-search .job-search-container .advanced-filters > div .custom-selectbox .ReactTags__tags .ReactTags__selected .ReactTags__tagInputField {
                width: 100%;
                margin: 0;
                max-width: 100%;
                padding: 0 15px;
                text-decoration: none;
                margin-right: auto;
                color: #838383;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: 0.12px;
                line-height: 19px;
                cursor: pointer; }
          .global-search .job-search-container .advanced-filters > div .custom-selectbox:after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #222;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            pointer-events: none; }
        .global-search .job-search-container .advanced-filters > div .ReactTags__tags {
          display: inline-block;
          width: 100%;
          padding: 0 8px;
          min-height: 43px;
          border: 1px solid #cfcfcf;
          border-radius: 21px; }
          .global-search .job-search-container .advanced-filters > div .ReactTags__tags .ReactTags__tagInputField {
            max-width: 200px; }
          .global-search .job-search-container .advanced-filters > div .ReactTags__tags .ReactTags__selected {
            margin-top: 8px; }
            .global-search .job-search-container .advanced-filters > div .ReactTags__tags .ReactTags__selected .ReactTags__tagInputField {
              background-color: transparent;
              border: none; }
            .global-search .job-search-container .advanced-filters > div .ReactTags__tags .ReactTags__selected .ReactTags__tagInputField,
            .global-search .job-search-container .advanced-filters > div .ReactTags__tags .ReactTags__selected .ReactTags__tag {
              margin-bottom: 8px; }
        .global-search .job-search-container .advanced-filters > div .custom-right {
          padding: 0px;
          display: inline-block;
          width: 100%; }
          .global-search .job-search-container .advanced-filters > div .custom-right > div {
            display: inline-block; }
            .global-search .job-search-container .advanced-filters > div .custom-right > div span {
              margin-right: 8px;
              font-weight: 500; }
          .global-search .job-search-container .advanced-filters > div .custom-right label,
          .global-search .job-search-container .advanced-filters > div .custom-right input {
            margin-top: 12px; }
          .global-search .job-search-container .advanced-filters > div .custom-right input.custom,
          .global-search .job-search-container .advanced-filters > div .custom-right select {
            margin: 0 16px 0 0px;
            padding: 6px 20px;
            height: 44px;
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: 22px;
            background-color: #ffffff; }
          .global-search .job-search-container .advanced-filters > div .custom-right input.custom {
            color: #838383;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.12px;
            line-height: 19px; }
      .global-search .job-search-container .advanced-filters .search-btn {
        margin-left: 15px;
        display: inline-block;
        padding: 11px 30px;
        border-radius: 21.5px;
        background-color: #45f6a6;
        color: #222222;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        min-width: 127px;
        border: 1px solid #45f6a6;
        margin-top: 20px; }
        @media only screen and (max-width: 480px) {
          .global-search .job-search-container .advanced-filters .search-btn {
            min-width: unset; } }
      .global-search .job-search-container .advanced-filters .clear-all-filters {
        border: 1px solid #2a4673;
        background-color: #fff;
        color: #2a4773; }
      @media only screen and (max-width: 767px) {
        .global-search .job-search-container .advanced-filters {
          padding: 15px; } }
    .global-search .job-search-container .visible {
      display: block;
      position: relative;
      z-index: 1000;
      width: 100%;
      max-width: 100%;
      background-color: white;
      border-radius: 200px;
      border: 2px solid transparent;
      transition: border 0.3s; }
      .global-search .job-search-container .visible .type {
        display: inline-block;
        padding: 0;
        z-index: 1;
        width: 154px;
        cursor: pointer;
        position: absolute;
        font-weight: 500;
        top: 50%;
        transform: translate(0, -50%); }
        .global-search .job-search-container .visible .type .dropdown > button {
          background-color: transparent !important;
          width: 154px; }
      .global-search .job-search-container .visible .separator {
        position: absolute;
        left: 148px;
        top: 14px;
        height: 36px;
        width: 1px;
        background-color: rgba(204, 204, 204, 0.5); }
      .global-search .job-search-container .visible .search-input {
        left: 154px;
        position: relative;
        display: inline-block;
        width: calc(100% - 152px);
        display: flex; }
      .global-search .job-search-container .visible .ReactTags__tags {
        padding: 0 0 0 12px;
        margin: 8px 0 8px 12px;
        flex-basis: calc(100% - 298px);
        background-color: #f5f5f5;
        border-radius: 52px 0 0 52px; }
        .global-search .job-search-container .visible .ReactTags__tags .ReactTags__selected {
          margin-top: 8px; }
          .global-search .job-search-container .visible .ReactTags__tags .ReactTags__selected .ReactTags__tagInputField,
          .global-search .job-search-container .visible .ReactTags__tags .ReactTags__selected .ReactTags__tag {
            margin-bottom: 8px; }
      .global-search .job-search-container .visible svg {
        position: absolute;
        top: -1px;
        width: 24px;
        height: auto;
        left: 0px;
        margin: 16px 14px; }
      .global-search .job-search-container .visible .search-btn {
        font-size: 13px;
        z-index: 1000;
        background-color: #2A4673;
        color: white;
        padding: 8px 24px 8px 22px;
        border: 0;
        font-weight: 500;
        height: 50px;
        margin-top: 8px;
        border-radius: 0 52px 52px 0;
        margin-right: 12px; }
        .global-search .job-search-container .visible .search-btn.mobile {
          display: none; }
      .global-search .job-search-container .visible[data-active="true"] {
        border: 2px solid #45F6A6;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    .global-search .job-search-container .dropdown.featured-tags {
      display: none;
      width: 100%;
      margin: auto;
      z-index: 10;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      display: block;
      margin-top: 8px; }
      .global-search .job-search-container .dropdown.featured-tags .featured-tags {
        display: flex;
        flex-wrap: nowrap;
        background-color: white;
        border-radius: 12px;
        box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.35);
        padding: 16px; }
        .global-search .job-search-container .dropdown.featured-tags .featured-tags > div {
          flex-basis: 20% !important;
          margin-right: 12px; }
          .global-search .job-search-container .dropdown.featured-tags .featured-tags > div:last-child {
            margin-right: 0; }
          .global-search .job-search-container .dropdown.featured-tags .featured-tags > div h3 {
            font-weight: 700;
            font-size: 0.9em;
            color: black; }
          .global-search .job-search-container .dropdown.featured-tags .featured-tags > div .tags-container {
            max-height: 212px;
            overflow: auto;
            display: block; }
            .global-search .job-search-container .dropdown.featured-tags .featured-tags > div .tags-container .tag {
              display: block;
              padding: 0;
              margin-bottom: 8px; }
              .global-search .job-search-container .dropdown.featured-tags .featured-tags > div .tags-container .tag div {
                background-color: #F5F5F5;
                cursor: pointer;
                font-weight: 500;
                padding: 4px 12px;
                font-size: 0.8em;
                display: inline-block;
                border-radius: 52px;
                margin: 0; }
                .global-search .job-search-container .dropdown.featured-tags .featured-tags > div .tags-container .tag div.active {
                  background-color: #45F6A6; }
  .global-search[data-next-search-type="candidate"] .dropdown.featured-tags .tag {
    background-color: white !important; }
    .global-search[data-next-search-type="candidate"] .dropdown.featured-tags .tag div {
      color: black; }
      .global-search[data-next-search-type="candidate"] .dropdown.featured-tags .tag div.active {
        background-color: #508bb0 !important;
        color: white !important; }
  .global-search .search-shortcuts-container {
    padding: 12px 0;
    text-align: center;
    margin-top: 24px; }
    .global-search .search-shortcuts-container .quick-search-options {
      display: inline-block;
      width: 100%;
      margin: auto; }
      .global-search .search-shortcuts-container .quick-search-options button {
        background-color: rgba(47, 33, 55, 0.5);
        color: white;
        border-radius: 52px;
        padding: 6px 20px;
        border: none;
        font-weight: 500;
        margin-left: 16px; }
        .global-search .search-shortcuts-container .quick-search-options button:hover {
          background-color: rgba(47, 33, 55, 0.7); }
    .global-search .search-shortcuts-container > label {
      color: white;
      float: left;
      display: none;
      margin: 8px 0; }
  .global-search[data-search-bar-active="true"] .dropdown.featured-tags {
    display: block; }
  .global-search[data-service="true"] .visible,
  .global-search[data-service="true"] .advanced-filters-toggle {
    display: none; }
  .global-search[data-home="true"] > .main {
    padding-bottom: 0em !important;
    display: flex;
    max-width: 1152px;
    padding-top: 7em; }
    .global-search[data-home="true"] > .main .featured-tags .featured-tags {
      overflow: auto; }
    .global-search[data-home="true"] > .main .job-search-container {
      width: calc(100% - 344px);
      align-self: center;
      padding: 4em 0; }
      @media only screen and (max-width: 991px) {
        .global-search[data-home="true"] > .main .job-search-container {
          width: 100%; } }
    .global-search[data-home="true"] > .main .reviews {
      width: 344px; }
    .global-search[data-home="true"] > .main .search-input .ReactTags__tags {
      flex-basis: calc(100% - 112px); }
    @media only screen and (max-width: 991px) {
      .global-search[data-home="true"] > .main {
        justify-content: center;
        padding-top: 0 !important; } }
  .global-search:not([data-home="true"]) > .main .job-search-container > h1,
  .global-search:not([data-home="true"]) > .main .job-search-container > h2 {
    display: none; }
  .global-search #location-picker {
    float: right; }
  .global-search[data-next-search-type="candidate"] .ReactTags__tag,
  .global-search[data-next-search-type="candidate"] .tag {
    background-color: #508bb0 !important;
    color: white !important; }
  @media (max-width: 1240px) {
    .global-search .featured-tags.dropdown {
      max-width: 960px !important; }
      .global-search .featured-tags.dropdown .tags-container.featured-tags {
        flex-wrap: wrap !important; }
        .global-search .featured-tags.dropdown .tags-container.featured-tags > div {
          flex-basis: calc(33.3333% - 12px) !important;
          overflow: hidden;
          height: 220px;
          margin-bottom: 12px; } }
  @media (max-width: 991px) {
    .global-search {
      min-height: 520px;
      display: flex;
      align-items: center; }
      .global-search .featured-tags.dropdown .featured-tags.tags-container {
        flex-wrap: wrap; }
        .global-search .featured-tags.dropdown .featured-tags.tags-container > div {
          flex-basis: 50% !important;
          margin-right: 0 !important;
          margin-bottom: 12px;
          height: 212px;
          overflow: auto; }
          .global-search .featured-tags.dropdown .featured-tags.tags-container > div:even {
            margin-right: 12px !important; } }
  @media (max-width: 860px) {
    .global-search .main-container {
      padding-bottom: 20px; }
    .global-search .additional-link {
      float: none;
      max-width: 200px;
      width: 100%;
      margin: auto;
      text-align: center;
      display: block; }
    .global-search .back-button {
      display: none !important; }
    .global-search .advanced-filters-toggle .cv-search-toggle {
      float: none !important;
      margin-top: 16px;
      display: block !important; }
    .global-search .advanced-filters-toggle .toggle,
    .global-search .advanced-filters-toggle .clear-all-filters {
      float: none !important; } }
  @media (max-width: 767px) {
    .global-search > .main {
      position: static;
      transform: none;
      width: 100%;
      max-width: 100%;
      padding-top: 8em !important; }
      .global-search > .main .job-search-container .visible {
        background: transparent;
        margin-top: 36px;
        border: none; }
        .global-search > .main .job-search-container .visible .type {
          position: absolute;
          width: 100%;
          top: -44px !important;
          text-align: center;
          left: 0;
          display: block;
          margin: auto;
          transform: none;
          margin-bottom: 8px; }
          .global-search > .main .job-search-container .visible .type .dropdown > button {
            color: white !important; }
        .global-search > .main .job-search-container .visible .hr {
          height: 38px;
          position: absolute;
          left: 154px;
          width: 1px;
          background: black;
          display: none; }
        .global-search > .main .job-search-container .visible .search-input {
          display: block;
          width: 100%;
          position: relative;
          left: 0;
          top: 0;
          border: 0;
          background-color: white;
          width: 100%;
          border-radius: 16px;
          overflow: hidden;
          text-align: center; }
          .global-search > .main .job-search-container .visible .search-input svg {
            display: none; }
          .global-search > .main .job-search-container .visible .search-input .ReactTags__tags {
            padding: 0px 0px 0px 0px;
            flex-basis: 100%;
            margin: 12px 12px 8px 12px !important;
            text-align: left;
            border-radius: 16px !important; }
            .global-search > .main .job-search-container .visible .search-input .ReactTags__tags .ReactTags__selected {
              padding-left: 10px; }
        .global-search > .main .job-search-container .visible .separator {
          display: none; }
      .global-search > .main .search-shortcuts-container .quick-search-options {
        padding: 0;
        width: 100%; }
        .global-search > .main .search-shortcuts-container .quick-search-options button {
          margin-bottom: 12px; }
      .global-search > .main .search-btn,
      .global-search > .main .advanced-filters-toggle {
        border-radius: 52px !important;
        display: inline-block !important;
        margin-bottom: 12px !important; }
      .global-search > .main .advanced-filters .salary-options {
        flex-direction: column !important; }
        .global-search > .main .advanced-filters .salary-options > div {
          flex-basis: 100% !important;
          margin-left: 0 !important;
          margin-right: 0 !important; }
          .global-search > .main .advanced-filters .salary-options > div.range {
            margin-bottom: 16px; }
          .global-search > .main .advanced-filters .salary-options > div.period {
            height: 42px; }
      .global-search > .main .advanced-filters > div > h3 {
        display: block;
        width: 100%; }
      .global-search > .main .advanced-filters > div .custom-right {
        padding: 20px 20px 24px 20px;
        width: 100%; } }

@media (max-width: 767px) and (max-width: 760px) {
  .global-search[data-home="true"] > .main {
    display: block !important; }
    .global-search[data-home="true"] > .main .job-search-container {
      width: 100%;
      padding: 0; }
      .global-search[data-home="true"] > .main .job-search-container > h3 {
        margin-bottom: 52px; }
    .global-search[data-home="true"] > .main .reviews {
      margin: 48px auto 0 auto;
      display: block;
      width: 100%;
      display: none; }
      .global-search[data-home="true"] > .main .reviews > div {
        justify-content: center; }
        .global-search[data-home="true"] > .main .reviews > div .person {
          max-width: calc(100% - 124px); } }
  @media (max-width: 575px) {
    .global-search {
      min-height: 400px; }
      .global-search .featured-tags.dropdown .featured-tags.tags-container {
        flex-wrap: wrap; }
        .global-search .featured-tags.dropdown .featured-tags.tags-container > div {
          flex-basis: 100% !important;
          margin-right: 0 !important;
          height: unset; } }
  @media (max-width: 450px) {
    .global-search .job-search-container .dropdown.featured-tags > div {
      max-height: 244px;
      overflow: scroll; }
    .global-search .cv-search-toggle .search-keyword {
      display: none; } }
  @media (max-width: 380px) {
    .global-search > .main {
      padding-left: 20px;
      padding-right: 20px; } }

#root > div[data-url*="/submission/"] .back-button {
  margin-left: 0; }

#root > div[data-url*="/submission/"] .global-search {
  padding: 0; }
  #root > div[data-url*="/submission/"] .global-search #submission-title,
  #root > div[data-url*="/submission/"] .global-search #submission-sub {
    color: white;
    font-weight: 700;
    text-align: center; }
    #root > div[data-url*="/submission/"] .global-search #submission-title#submission-title,
    #root > div[data-url*="/submission/"] .global-search #submission-sub#submission-title {
      font-size: 2.4em; }
    #root > div[data-url*="/submission/"] .global-search #submission-title#submission-sub,
    #root > div[data-url*="/submission/"] .global-search #submission-sub#submission-sub {
      font-size: 1.4em;
      font-weight: 500; }
  #root > div[data-url*="/submission/"] .global-search .main {
    padding: 8.4em 0 3em 0; }

.search-options {
  background-color: #f5f5f5;
  padding: 35px 0; }
  .search-options h3 {
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.18px;
    line-height: 29px;
    margin: 0; }
  .search-options .sort-wrapper .dropdown-toggle {
    background-color: transparent !important;
    color: #222222 !important;
    font-size: 14px;
    letter-spacing: 0.11px;
    line-height: 18px;
    font-weight: 300; }
  .search-options .sort-wrapper .dropdown-menu {
    padding: 20px;
    min-width: 145px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    left: unset !important;
    right: 0 !important;
    top: 100% !important;
    transform: unset !important; }
    .search-options .sort-wrapper .dropdown-menu .dropdown-item {
      color: #221818;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      border: none; }

.searchjob-list {
  background-color: #fff;
  padding: 50px 0px 0; }
  .searchjob-list > .title {
    color: #222222;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.18px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 30px; }
  .searchjob-list .faq {
    border: 1px solid #bcbcbf; }
    .searchjob-list .faq .title {
      border: 0;
      border-radius: 6px; }
    .searchjob-list .faq.faq[data-is-open="true"] .title {
      border-bottom: 0;
      border-radius: 6px 6px 0 0; }
    .searchjob-list .faq .card .card-body {
      padding-top: 0; }
    .searchjob-list .faq .collapse.show {
      border: unset;
      border-top: 0; }
  .searchjob-list .why-choose-gradbay {
    display: none; }

.faq-popular-search {
  border-top: 1px solid #a9cea440; }

body .pagination-wrapper .pagination {
  background: #fff;
  padding: 6px 5px;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px; }
  body .pagination-wrapper .pagination li {
    background-color: #f5f5f5 !important;
    width: auto !important;
    height: auto !important;
    padding: 0 3px;
    margin-right: 0 !important; }
    body .pagination-wrapper .pagination li a {
      color: #77838f !important;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 14px;
      top: 0px !important;
      border: 1px solid #bebfbf;
      padding: 5px 8px;
      border-radius: 4px;
      min-width: 30px;
      background: #fff; }
      body .pagination-wrapper .pagination li a:hover {
        background-color: #eee5ff;
        border-color: #d6c5f5; }
      @media only screen and (max-width: 767px) {
        body .pagination-wrapper .pagination li a {
          font-size: 12px; } }
    body .pagination-wrapper .pagination li.active {
      background-color: transparent !important; }
      body .pagination-wrapper .pagination li.active a {
        background-color: #eee5ff;
        border-color: #e6d8ff;
        color: #9774d7 !important;
        font-weight: 600; }

.current-search {
  padding: 0;
  padding-top: 30px; }
  .current-search .current-search-wrap {
    background: #fff;
    padding: 30px 20px;
    border-radius: 8px; }
    .current-search .current-search-wrap h3 {
      color: #2c2c2c;
      font-size: 22px;
      font-weight: 700;
      letter-spacing: 0.14px;
      line-height: 28px; }
      @media only screen and (max-width: 767px) {
        .current-search .current-search-wrap h3 {
          margin-bottom: 20px;
          margin-right: 0; } }
    .current-search .current-search-wrap .tags-container .tag {
      background-color: #45f6a6 !important; }
    @media only screen and (max-width: 575px) {
      .current-search .current-search-wrap .tags-container {
        max-width: 100%; } }
    @media only screen and (max-width: 575px) {
      .current-search .current-search-wrap {
        padding: 15px; } }

.home-page-gradbay .job-search-container .visible {
  display: none; }

.search-job-list .global-search {
  display: none; }

.search-job-list .search-job-banner {
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffb3b5;
  position: relative; }
  .search-job-list .search-job-banner:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../icons/banner-top.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 280px; }
    @media (max-width: 768px) {
      .search-job-list .search-job-banner:after {
        background-size: 200px; } }
  .search-job-list .search-job-banner:before {
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../icons/banner-bottom.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 280px; }
    @media (max-width: 768px) {
      .search-job-list .search-job-banner:before {
        background-size: 200px; } }
  .search-job-list .search-job-banner h1 {
    text-align: center;
    color: #000032;
    font-size: 34px;
    line-height: 42px;
    position: relative;
    z-index: 1; }
  .search-job-list .search-job-banner .switch {
    -webkit-appearance: auto !important; }
  .search-job-list .search-job-banner.grad-search-banner {
    flex-direction: column; }
    .search-job-list .search-job-banner.grad-search-banner .search-video-profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1;
      position: relative; }
      .search-job-list .search-job-banner.grad-search-banner .search-video-profile .toggle {
        margin: 0 10px; }
        .search-job-list .search-job-banner.grad-search-banner .search-video-profile .toggle .tgl-btn {
          margin-bottom: 0;
          border-width: 2px;
          border-color: #000032; }
        .search-job-list .search-job-banner.grad-search-banner .search-video-profile .toggle .tgl-flat + .tgl-btn {
          background-color: transparent; }
        .search-job-list .search-job-banner.grad-search-banner .search-video-profile .toggle .tgl-flat:checked + .tgl-btn {
          border-color: #fff; }
        .search-job-list .search-job-banner.grad-search-banner .search-video-profile .toggle .tgl-flat + .tgl-btn:after {
          background: #000032; }
        .search-job-list .search-job-banner.grad-search-banner .search-video-profile .toggle .tgl-flat:checked + .tgl-btn:after {
          background: #000032; }
      @media only screen and (max-width: 767px) {
        .search-job-list .search-job-banner.grad-search-banner .search-video-profile {
          flex-direction: column; }
          .search-job-list .search-job-banner.grad-search-banner .search-video-profile .toggle {
            margin: 10px 0; } }
    .search-job-list .search-job-banner.grad-search-banner p {
      color: #000032;
      font-size: 14px;
      font-weight: 600;
      margin: 0; }

.advance-search .header-field-box h3 {
  color: #000032;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 29px;
  margin-bottom: 0; }

.advance-search .global-search {
  background: #f5f5f5;
  padding-top: 110px;
  position: relative; }
  .advance-search .global-search .form-group.select {
    margin-bottom: 0; }
  .advance-search .global-search .job-search-container {
    padding-bottom: 50px; }
    .advance-search .global-search .job-search-container .advanced-filters > div {
      min-height: unset; }
      .advance-search .global-search .job-search-container .advanced-filters > div h5 {
        margin-top: 25px;
        margin-bottom: 13px; }
    .advance-search .global-search .job-search-container .advanced-filters .date-range-picker-custom {
      margin-bottom: 20px; }
    .advance-search .global-search .job-search-container .advanced-filters h4 {
      font-size: 18px;
      font-weight: 600; }
    .advance-search .global-search .job-search-container .advanced-filters h3.main-head {
      margin-top: 0 !important;
      font-size: 24px;
      margin-bottom: 30px !important; }
      @media only screen and (max-width: 767px) {
        .advance-search .global-search .job-search-container .advanced-filters h3.main-head {
          font-size: 16px; } }
    .advance-search .global-search .job-search-container .advanced-filters-toggle {
      margin-top: 0;
      position: absolute;
      top: 25px;
      right: 25px; }
      .advance-search .global-search .job-search-container .advanced-filters-toggle .cv-search-toggle {
        display: flex;
        white-space: nowrap;
        align-items: center; }
      .advance-search .global-search .job-search-container .advanced-filters-toggle p {
        color: #222222;
        font-size: 12px !important;
        font-weight: 500; }
      .advance-search .global-search .job-search-container .advanced-filters-toggle .toggle {
        display: flex;
        top: 0; }
        .advance-search .global-search .job-search-container .advanced-filters-toggle .toggle .tgl-btn {
          border-color: #45f6a6 !important;
          width: 40px;
          height: 26px; }
          .advance-search .global-search .job-search-container .advanced-filters-toggle .toggle .tgl-btn:after {
            background: #45f6a6 !important;
            width: 16px;
            height: 16px; }
      @media only screen and (max-width: 991px) {
        .advance-search .global-search .job-search-container .advanced-filters-toggle {
          position: static; } }
      @media only screen and (max-width: 767px) {
        .advance-search .global-search .job-search-container .advanced-filters-toggle {
          margin-bottom: 20px;
          width: 100%; }
          .advance-search .global-search .job-search-container .advanced-filters-toggle .cv-search-toggle {
            display: flex !important;
            width: 100%; } }
    @media only screen and (max-width: 767px) {
      .advance-search .global-search .job-search-container .mobile-reverse {
        flex-direction: column-reverse; }
        .advance-search .global-search .job-search-container .mobile-reverse h3 {
          margin-bottom: 20px; }
      .advance-search .global-search .job-search-container .mobile-column {
        flex-direction: column; } }
  .advance-search .global-search .authorised-radio {
    display: flex; }
    .advance-search .global-search .authorised-radio .control {
      color: #222222;
      font-size: 14px !important;
      letter-spacing: 0.2px;
      line-height: 21px;
      font-weight: 400;
      padding-left: 40px;
      color: #fff;
      font-weight: 500;
      position: relative; }
      .advance-search .global-search .authorised-radio .control.left {
        margin-right: 30px; }
        @media only screen and (max-width: 767px) {
          .advance-search .global-search .authorised-radio .control.left {
            margin-right: 20px;
            padding-left: 35px; } }
      .advance-search .global-search .authorised-radio .control .control__indicator {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-53%);
        -webkit-transform: translateY(-53%);
        -moz-transform: translateY(-53%);
        height: 26px;
        width: 26px;
        border: 2px solid #bcbcbf;
        border-radius: 21px;
        background-color: transparent !important; }
        .advance-search .global-search .authorised-radio .control .control__indicator:before {
          position: absolute;
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 21px;
          background-color: #45f6a6;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          display: none; }
        .advance-search .global-search .authorised-radio .control .control__indicator:after {
          content: ""; }
      .advance-search .global-search .authorised-radio .control input:checked ~ .control__indicator {
        border-color: #45f6a6 !important; }
        .advance-search .global-search .authorised-radio .control input:checked ~ .control__indicator:before {
          display: block; }
      .advance-search .global-search .authorised-radio .control input:checked ~ .control__indicator ~ span {
        color: #45f6a6; }
  .advance-search .global-search:before {
    content: "";
    min-height: 220px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    background: #ffb3b5; }
  .advance-search .global-search .job-search-container {
    display: block;
    margin-top: -50px; }
    .advance-search .global-search .job-search-container .green-tags .ReactTags__selected .ReactTags__tag {
      background-color: #6946a0 !important; }
    .advance-search .global-search .job-search-container .green-tags .authorised-radio label {
      color: #222222; }
    .advance-search .global-search .job-search-container .green-tags input:checked ~ .control__indicator {
      border-color: #651ba0 !important; }
      .advance-search .global-search .job-search-container .green-tags input:checked ~ .control__indicator:before {
        display: block;
        background-color: #651ba0; }
    .advance-search .global-search .job-search-container .green-tags input:checked ~ .control__indicator ~ span {
      color: #651ba0; }

.advance-search .ReactTags__tags
.ReactTags__selected
.ReactTags__tagInput
.ReactTags__suggestions {
  left: 0;
  top: 100%;
  /* &::-webkit-scrollbar {
          width: 5px;
        }

       
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px #ddd; 
          border-radius: 10px;
        }
         
       
        &::-webkit-scrollbar-thumb {
          background: #ccc; 
          border-radius: 10px;
        }

       
        &::-webkit-scrollbar-thumb:hover {
          background: #eee; 
        }*/ }
  .advance-search .ReactTags__tags
.ReactTags__selected
.ReactTags__tagInput
.ReactTags__suggestions::-webkit-scrollbar {
    width: 20px;
    /* width of the entire scrollbar */
    border-radius: 10px; }
  .advance-search .ReactTags__tags
.ReactTags__selected
.ReactTags__tagInput
.ReactTags__suggestions::-webkit-scrollbar-track {
    background: #ededf1;
    border-radius: 0 10px 10px 0; }
  .advance-search .ReactTags__tags
.ReactTags__selected
.ReactTags__tagInput
.ReactTags__suggestions::-webkit-scrollbar-thumb {
    background-color: #dcdbe3;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
    border: 6px solid #ededf1;
    /* creates padding around scroll thumb */ }

.advance-search .ReactTags__tags
.ReactTags__selected
.ReactTags__tagInput
.ReactTags__suggestions
ul {
  padding: 10px 0;
  width: 100%; }

.advance-search .ReactTags__tags
.ReactTags__selected
.ReactTags__tagInput
.ReactTags__suggestions
ul
li {
  font-size: 15px;
  letter-spacing: 0.12px;
  color: #656363;
  border-bottom: none;
  padding: 10px 20px; }

.advance-search .ReactTags__tags
.ReactTags__selected
.ReactTags__tagInput
.ReactTags__suggestions
ul
li.ReactTags__activeSuggestion {
  background: #ededf1; }

.form-group.select.job-grad-radio .authorised-radio .control .control__indicator {
  border: 2px solid #fff; }

.search-job-list .current-search .current-search-wrap .tags-container.current-search-result {
  width: 100%;
  padding: 20px 0px;
  margin-bottom: -10px; }
  .search-job-list .current-search .current-search-wrap .tags-container.current-search-result .each-tags {
    display: inline-block; }
    .search-job-list .current-search .current-search-wrap .tags-container.current-search-result .each-tags:last-child {
      padding-right: 110px; }
    .search-job-list .current-search .current-search-wrap .tags-container.current-search-result .each-tags .tag-name {
      color: #222222;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.11px;
      line-height: 18px;
      margin: 0px 15px;
      display: inline-block; }
    .search-job-list .current-search .current-search-wrap .tags-container.current-search-result .each-tags .tags {
      padding: 10px 24px; }

.search-job-list .current-search .current-search-wrap .clear-filter {
  border: 1px solid #45f6a6;
  border-radius: 45px;
  color: #45f6a6;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  padding: 9px 10px;
  line-height: 16px;
  background: transparent;
  min-width: 105px;
  position: absolute;
  bottom: 20px;
  right: 0; }
  @media only screen and (max-width: 767px) {
    .search-job-list .current-search .current-search-wrap .clear-filter {
      right: 15px; } }

.welcome_popup_container {
  max-width: 100% !important; }
  .welcome_popup_container .modal-content {
    background-color: #0000; }

.welcome_popup {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 99999999999;
  background-color: #0000;
  margin: auto; }
  .welcome_popup .login-right {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9999999999999999;
    text-decoration: none;
    color: #000; }
  .welcome_popup_main {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 0 4px #777;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding: 0; }
    @media (max-width: 767px) {
      .welcome_popup_main {
        width: 90%; } }
    .welcome_popup_main .popup_right {
      width: 100%;
      padding: 40px 30px 30px;
      height: 100%;
      position: relative;
      color: #000;
      text-align: center; }
      .welcome_popup_main .popup_right a {
        font-size: 18px;
        font-weight: 500;
        background-color: #00f9a6;
        color: #000;
        padding: 13px 60px;
        border-radius: 25px;
        border: none;
        cursor: pointer;
        margin-bottom: 25px;
        display: inline-block; }
        .welcome_popup_main .popup_right a:hover {
          text-decoration: none !important; }
      .welcome_popup_main .popup_right h2 {
        font-size: 30px;
        margin-bottom: 10px;
        line-height: normal;
        font-weight: 600; }
        @media (max-width: 767px) {
          .welcome_popup_main .popup_right h2 {
            font-size: 24px; } }
      .welcome_popup_main .popup_right .subheading {
        font-size: 16px;
        font-weight: 500;
        line-height: 30px; }
        @media (max-width: 767px) {
          .welcome_popup_main .popup_right .subheading {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px; }
            .welcome_popup_main .popup_right .subheading br {
              display: none; } }
      .welcome_popup_main .popup_right ul {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        list-style: none;
        width: 100%; }
        @media (max-width: 767px) {
          .welcome_popup_main .popup_right ul {
            flex-direction: column; } }
        .welcome_popup_main .popup_right ul li {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 0; }
          @media (max-width: 767px) {
            .welcome_popup_main .popup_right ul li:first-child {
              margin-bottom: 50px; } }
        .welcome_popup_main .popup_right ul .icon {
          margin-bottom: 30px; }
          @media (max-width: 767px) {
            .welcome_popup_main .popup_right ul .icon {
              margin-bottom: 10px; } }
          .welcome_popup_main .popup_right ul .icon img {
            width: 300px;
            object-fit: contain; }
            @media (max-width: 767px) {
              .welcome_popup_main .popup_right ul .icon img {
                width: 150px;
                height: auto;
                margin-bottom: 10px; } }
        .welcome_popup_main .popup_right ul button {
          font-size: 18px;
          margin-bottom: 0;
          font-weight: 500;
          background-color: #00f9a6;
          color: #000;
          border-radius: 25px;
          display: block;
          border: none;
          cursor: pointer;
          height: 55px;
          min-width: 230px; }
          .welcome_popup_main .popup_right ul button:hover {
            text-decoration: none !important; }

.advance_search_wrap .banner_shades {
  position: absolute;
  width: 100%;
  height: 220px;
  top: 0;
  left: 0;
  z-index: 0; }
  .advance_search_wrap .banner_shades:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../icons/banner-top.png");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 280px; }
    @media (max-width: 768px) {
      .advance_search_wrap .banner_shades:after {
        background-size: 130px; } }
  .advance_search_wrap .banner_shades:before {
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../icons/banner-bottom.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 280px; }
    @media (max-width: 768px) {
      .advance_search_wrap .banner_shades:before {
        background-size: 130px; } }

.advance_search_wrap .mobile-reverse {
  position: relative;
  z-index: 1; }
