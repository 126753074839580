@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Poppins", sans-serif; }

.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

.statistics-home {
  background: #ffb3b5;
  min-height: 430px; }
  .statistics-home h2 {
    color: #222222;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
    padding: 20px 0;
    margin: 0; }
  .statistics-home .background {
    max-width: 100% !important;
    background: #ffb3b5;
    border-radius: 0 !important; }
    .statistics-home .background .grid {
      max-width: 1140px;
      width: 100%;
      border-radius: 0px;
      overflow: hidden;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%); }
      .statistics-home .background .grid .Registered-users,
      .statistics-home .background .grid .stem {
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 767px) {
          .statistics-home .background .grid .Registered-users,
          .statistics-home .background .grid .stem {
            flex-wrap: wrap; } }
      .statistics-home .background .grid .Registered-users {
        background-color: #fff;
        padding: 50px 0px 40px; }
        .statistics-home .background .grid .Registered-users .metric p {
          color: #222222;
          font-size: 50px;
          font-weight: 600;
          letter-spacing: 0;
          text-align: center;
          background-image: url("../images/Frame.png");
          min-height: 180px;
          min-width: 180px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 15px;
          line-height: 180px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center; }
          @media only screen and (max-width: 1024px) {
            .statistics-home .background .grid .Registered-users .metric p {
              font-size: 40px;
              min-height: 150px;
              min-width: 150px;
              line-height: 150px; } }
        .statistics-home .background .grid .Registered-users .metric label {
          color: #000;
          font-size: 21px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 32px;
          text-align: center;
          margin-bottom: 0; }
          @media only screen and (max-width: 1024px) {
            .statistics-home .background .grid .Registered-users .metric label {
              font-size: 19px; } }
        .statistics-home .background .grid .Registered-users .circluar-graph.female .CircularProgressbar-path {
          stroke: #bd99ff; }
        .statistics-home .background .grid .Registered-users .circluar-graph.bame .CircularProgressbar-path {
          stroke: #45f6a6; }
        .statistics-home .background .grid .Registered-users .circluar-graph.lgbt .CircularProgressbar-path {
          stroke: #ffb3b5; }
        .statistics-home .background .grid .Registered-users .circluar-graph label {
          color: #222222;
          font-size: 21px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 32px;
          text-align: center;
          margin-bottom: 0; }
      .statistics-home .background .grid .stem {
        background-color: #fff;
        padding: 20px 0px 45px; }
        .statistics-home .background .grid .stem .metric.steam p {
          color: #bd99ff; }
        .statistics-home .background .grid .stem .metric.universities p {
          color: #45f6a6; }
        @media only screen and (max-width: 767px) {
          .statistics-home .background .grid .stem .metric.video {
            margin-bottom: 0px; } }
        .statistics-home .background .grid .stem .metric.video p {
          color: #ffb3b5; }
        @media only screen and (max-width: 767px) {
          .statistics-home .background .grid .stem .metric.lang_covered {
            margin-bottom: 0px; } }
        .statistics-home .background .grid .stem .metric.lang_covered p {
          color: #45f6a6; }
        .statistics-home .background .grid .stem .metric p {
          color: #222222;
          font-size: 50px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 32px;
          text-align: center;
          margin-bottom: 20px; }
          @media only screen and (max-width: 1024px) {
            .statistics-home .background .grid .stem .metric p {
              font-size: 40px;
              margin-bottom: 10px; } }
        .statistics-home .background .grid .stem .metric label {
          color: #000000;
          font-size: 19px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 32px;
          text-align: center;
          margin-bottom: 0; }
          @media only screen and (max-width: 1024px) {
            .statistics-home .background .grid .stem .metric label {
              font-size: 16px; } }
      .statistics-home .background .grid .metric {
        text-align: center;
        flex-basis: 33.3333%; }
        .statistics-home .background .grid .metric svg {
          max-width: 180px;
          display: block;
          margin: auto;
          margin-bottom: 15px; }
          @media (max-width: 1024px) {
            .statistics-home .background .grid .metric svg {
              max-width: 150px; } }
          .statistics-home .background .grid .metric svg .CircularProgressbar-trail {
            stroke: #c3ffff;
            stroke-width: 8px; }
          .statistics-home .background .grid .metric svg .CircularProgressbar-path {
            stroke: #6deea5;
            stroke-linecap: unset;
            stroke-width: 9px; }
          .statistics-home .background .grid .metric svg .CircularProgressbar-text {
            fill: #000;
            font-weight: 600;
            font-size: 30px;
            transform: translate(0px, 1px); }
            @media (max-width: 1024px) {
              .statistics-home .background .grid .metric svg .CircularProgressbar-text {
                font-size: 27px; } }
        .statistics-home .background .grid .metric a {
          display: block;
          border: 1px solid white;
          width: 152px;
          background: white;
          margin: auto;
          color: black;
          border-radius: 52px;
          padding: 4px 12px;
          font-weight: 500;
          cursor: pointer; }
        .statistics-home .background .grid .metric:nth-child(7), .statistics-home .background .grid .metric:nth-child(8), .statistics-home .background .grid .metric:nth-child(9) {
          margin-bottom: 0; }
        @media only screen and (max-width: 767px) {
          .statistics-home .background .grid .metric {
            flex-basis: 50% !important;
            margin-bottom: 30px; } }
        @media only screen and (max-width: 575px) {
          .statistics-home .background .grid .metric {
            flex-basis: 100% !important; } }
      @media only screen and (max-width: 1199px) {
        .statistics-home .background .grid {
          max-width: 800px; } }
      @media only screen and (max-width: 991px) {
        .statistics-home .background .grid {
          max-width: 700px; } }
      @media only screen and (max-width: 767px) {
        .statistics-home .background .grid {
          padding: 0px 20px 20px 20px;
          position: static !important;
          transform: none !important;
          max-width: 100% !important; } }
  @media (min-width: 1350px) {
    .statistics-home {
      padding-top: 3em; }
      .statistics-home h2 {
        padding-top: 0; }
      .statistics-home .background {
        margin: auto;
        max-width: 1200px;
        border-radius: 12px; } }
  .statistics-home.graduate-home-stats .background .grid .Registered-users {
    background-color: #fff;
    padding: 0; }
    .statistics-home.graduate-home-stats .background .grid .Registered-users .metric.circluar-graph {
      position: relative;
      padding: 45px 0 30px; }
      .statistics-home.graduate-home-stats .background .grid .Registered-users .metric.circluar-graph:before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 150px;
        width: 1px;
        background-color: #ddd; }
      .statistics-home.graduate-home-stats .background .grid .Registered-users .metric.circluar-graph:last-child:before {
        display: none; }
      @media only screen and (max-width: 767px) {
        .statistics-home.graduate-home-stats .background .grid .Registered-users .metric.circluar-graph {
          padding: 30px 0;
          margin-bottom: 0; } }
