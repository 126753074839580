.toasts-container .toast toast-error, .toasts-container .undefined, .toasts-container .sc-bwzfXH, .toasts-container .hBChTE {
  max-height: initial;
  color: #fff; }

body.modal-open .navbar-container {
  z-index: 999; }

body iframe + div {
  z-index: 1000 !important; }

.navbar-container {
  padding: 10px 0;
  /*display: none;*/
  background-color: #fff;
  transition: 0.4s all ease-in-out;
  -webkit-transition: 0.4s all ease-in-out;
  -moz-transition: 0.4s all ease-in-out;
  /*z-index: 9999999999;*/ }
  .navbar-container .navbar {
    background-color: transparent !important;
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    z-index: 99;
    /* & [data-open=true] .navbar-toggler{
            background-color: #fff;
            span{
                color: #222;
            }
        }*/ }
    .navbar-container .navbar .site-logo {
      position: relative;
      z-index: 992; }
      .navbar-container .navbar .site-logo .navbar-brand {
        padding: 0;
        margin: 0; }
        .navbar-container .navbar .site-logo .navbar-brand img {
          max-width: 110px; }
          @media only screen and (max-width: 374px) {
            .navbar-container .navbar .site-logo .navbar-brand img {
              max-width: 90px; } }
    .navbar-container .navbar .mobile-user {
      display: none;
      align-items: center;
      margin-left: auto; }
      .navbar-container .navbar .mobile-user .favorites {
        position: relative;
        margin-left: 15px; }
        @media only screen and (max-width: 374px) {
          .navbar-container .navbar .mobile-user .favorites .nav-link {
            padding-left: 0; } }
        .navbar-container .navbar .mobile-user .favorites .wish-list-count {
          position: absolute;
          height: 16px;
          width: 16px;
          border: 1px solid #FFFFFF;
          background-color: #FF5700;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 5px;
          right: 8px;
          font-size: 8px;
          font-weight: 600;
          letter-spacing: 0.06px;
          color: #fff; }
      .navbar-container .navbar .mobile-user .messages {
        position: relative;
        padding-left: 0; }
        .navbar-container .navbar .mobile-user .messages span.message-count {
          position: absolute;
          height: 16px;
          width: 16px;
          border: 1px solid #FFFFFF;
          background-color: #FF5700;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 5px;
          right: 8px;
          font-size: 8px;
          font-weight: 600;
          letter-spacing: 0.06px;
          color: #fff; }
      .navbar-container .navbar .mobile-user .profile-dropdown-container {
        display: flex;
        align-items: center;
        margin-left: 0px; }
        .navbar-container .navbar .mobile-user .profile-dropdown-container .nav-link {
          padding: 0; }
          .navbar-container .navbar .mobile-user .profile-dropdown-container .nav-link .profile-img {
            width: 32px;
            height: 32px;
            border-radius: 50%; }
            @media only screen and (max-width: 374px) {
              .navbar-container .navbar .mobile-user .profile-dropdown-container .nav-link .profile-img {
                width: 25px;
                height: 25px; } }
          .navbar-container .navbar .mobile-user .profile-dropdown-container .nav-link span {
            display: inline-block;
            margin-left: 8px;
            color: #222222;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.1px;
            line-height: 15px;
            display: none; }
        .navbar-container .navbar .mobile-user .profile-dropdown-container .dropdown .dropdown-toggle {
          border-radius: 0;
          padding: 0px 0;
          background-color: transparent !important;
          color: #222 !important; }
          .navbar-container .navbar .mobile-user .profile-dropdown-container .dropdown .dropdown-toggle:after {
            margin-left: 5px;
            vertical-align: 4px; }
        .navbar-container .navbar .mobile-user .profile-dropdown-container .dropdown .dropdown-menu {
          padding: 0;
          border-radius: 6px;
          overflow: hidden;
          right: 0 !important;
          left: auto !important;
          min-width: 120px !important;
          background-color: #fff !important; }
          .navbar-container .navbar .mobile-user .profile-dropdown-container .dropdown .dropdown-menu .dropdown-item {
            display: block;
            color: #222222;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.1px;
            line-height: 15px;
            text-decoration: none;
            padding: 10px 15px;
            text-align: left; }
      @media only screen and (max-width: 991px) {
        .navbar-container .navbar .mobile-user {
          display: flex; } }
    .navbar-container .navbar .navbar-toggler {
      position: relative;
      z-index: 992;
      padding: 5px 15px;
      border: none;
      line-height: unset;
      font-size: unset;
      height: 40px;
      width: 40px;
      padding: 7px 0px; }
      .navbar-container .navbar .navbar-toggler .nav-icon {
        transform: rotate(0deg);
        z-index: 992;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        cursor: pointer;
        position: relative;
        height: 30px;
        width: 30px;
        margin-left: auto; }
        .navbar-container .navbar .navbar-toggler .nav-icon span {
          position: absolute;
          display: block;
          background: linear-gradient(90deg, #C076F0 0%, #43ABA3 100%);
          background-color: #317d8b;
          border-radius: 8px;
          opacity: 1;
          transition: all 0.25s ease-in-out;
          -webkit-transition: all .25s ease-in-out;
          -moz-transition: all .25s ease-in-out;
          -ms-transition: all .25s ease-in-out; }
        .navbar-container .navbar .navbar-toggler .nav-icon span:nth-child(1) {
          width: 25px;
          height: 4px;
          top: 5px;
          right: 0;
          border-radius: 45px; }
        .navbar-container .navbar .navbar-toggler .nav-icon span:nth-child(2) {
          width: 15px;
          height: 4px;
          right: 0px;
          top: 12px;
          border-radius: 45px; }
        .navbar-container .navbar .navbar-toggler .nav-icon span:nth-child(3) {
          width: 25px;
          height: 4px;
          top: 19px;
          right: 0;
          border-radius: 45px; }
        @media only screen and (max-width: 374px) {
          .navbar-container .navbar .navbar-toggler .nav-icon span:nth-child(1),
          .navbar-container .navbar .navbar-toggler .nav-icon span:nth-child(3) {
            width: 20px;
            height: 3px; } }
        @media only screen and (max-width: 374px) {
          .navbar-container .navbar .navbar-toggler .nav-icon span:nth-child(2) {
            width: 12px;
            height: 3px; } }
      .navbar-container .navbar .navbar-toggler .open span:nth-child(1) {
        top: 12px;
        transform: rotate(45deg); }
      .navbar-container .navbar .navbar-toggler .open span:nth-child(3) {
        top: 12px;
        transform: rotate(-45deg); }
      .navbar-container .navbar .navbar-toggler .open span:nth-child(2) {
        right: -100%;
        transition: all 0.4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        -moz-transition: all .4s ease-in-out;
        -ms-transition: all .4s ease-in-out;
        opacity: 0; }
      .navbar-container .navbar .navbar-toggler:nth-child(4) {
        left: 525px; }
    .navbar-container .navbar .navbar-collapse {
      justify-content: flex-end;
      transition: .4s all ease-in-out; }
      .navbar-container .navbar .navbar-collapse .navbar-nav {
        display: flex;
        align-items: center; }
        .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item {
          margin-left: 15px; }
          .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
            cursor: pointer;
            color: #222222;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.1px;
            line-height: 15px;
            padding: 0; }
            @media only screen and (max-width: 1199px) {
              .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
                font-size: 11px; } }
            @media only screen and (max-width: 991px) {
              .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
                padding: 0 30px;
                font-size: 15px !important; } }
            @media only screen and (max-width: 575px) {
              .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
                padding: 0 15px; } }
          .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item .gpt_ad_writer {
            display: flex;
            align-items: center;
            gap: 2px;
            border: 1px solid #45F6A6;
            border-radius: 25px;
            padding: 6px 8px; }
          .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item .messages {
            position: relative; }
            .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item .messages span.message-count {
              position: absolute;
              height: 16px;
              width: 16px;
              border: 1px solid #FFFFFF;
              background-color: #FF5700;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              top: -7px;
              right: -7px;
              font-size: 8px;
              font-weight: 600;
              letter-spacing: 0.06px;
              color: #fff; }
          @media only screen and (max-width: 1199px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item {
              margin-left: 7px; } }
          @media only screen and (max-width: 991px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .nav-item {
              margin: 0;
              margin-bottom: 20px;
              width: 100%; } }
        .navbar-container .navbar .navbar-collapse .navbar-nav .advanced-search .nav-link {
          border: 1px solid #45f6a6;
          border-radius: 19px;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.1px;
          line-height: 15px;
          padding: 8px 12px;
          background-color: #45f6a6;
          color: #222222 !important; }
          @media only screen and (max-width: 991px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .advanced-search .nav-link {
              border: none;
              border-radius: 0;
              padding: 0 30px;
              background-color: transparent; } }
          @media only screen and (max-width: 575px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .advanced-search .nav-link {
              padding: 0 15px; } }
        @media only screen and (max-width: 991px) {
          .navbar-container .navbar .navbar-collapse .navbar-nav .advanced-search {
            margin: 0;
            margin-bottom: 20px; } }
        .navbar-container .navbar .navbar-collapse .navbar-nav .advanced-search.post_free_job a {
          border: 1px solid #485BFF;
          background-color: #485BFF;
          color: #fff !important;
          border-radius: 25px; }
          @media only screen and (max-width: 991px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .advanced-search.post_free_job a {
              padding: 10px 10px;
              margin: 0 30px; } }
          @media only screen and (max-width: 640px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .advanced-search.post_free_job a {
              padding: 10px 10px;
              margin: 0 15px; } }
        .navbar-container .navbar .navbar-collapse .navbar-nav .advanced-search.for_grads_wrap div {
          border-radius: 25px;
          cursor: pointer; }
        .navbar-container .navbar .navbar-collapse .navbar-nav .favorites {
          position: relative;
          margin-left: 15px; }
          .navbar-container .navbar .navbar-collapse .navbar-nav .favorites .wish-list-count {
            position: absolute;
            height: 16px;
            width: 16px;
            border: 1px solid #FFFFFF;
            background-color: #FF5700;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -7px;
            right: -7px;
            font-size: 8px;
            font-weight: 600;
            letter-spacing: 0.06px;
            color: #fff; }
        .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup {
          display: flex;
          align-items: center;
          padding: 8px 0px 7px;
          padding-left: 20px;
          margin-left: 20px;
          border-left: 1px solid #D8D8D8; }
          .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .user-icon {
            margin-right: 8px;
            display: flex;
            align-items: center; }
            @media only screen and (max-width: 991px) {
              .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .user-icon {
                display: none; } }
          .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .nav-item {
            margin: 0; }
            @media only screen and (max-width: 991px) {
              .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .nav-item .nav-link {
                padding: 0 30px; } }
            @media only screen and (max-width: 575px) {
              .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .nav-item .nav-link {
                padding: 0 15px; } }
            @media only screen and (max-width: 991px) {
              .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .nav-item {
                margin-bottom: 20px; } }
          .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .divider {
            margin-right: 7px;
            position: relative; }
            .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .divider:after {
              position: absolute;
              content: "/";
              top: -2px;
              right: -6px;
              height: 15px;
              color: #222;
              overflow: hidden; }
              @media only screen and (max-width: 991px) {
                .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .divider:after {
                  display: none; } }
            @media only screen and (max-width: 767px) {
              .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup .divider {
                margin-right: 0; } }
          @media only screen and (max-width: 991px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup {
              width: 100%;
              border: none;
              flex-direction: column;
              margin: 0;
              padding: 0; } }
          .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup.logged-in {
            padding-left: 0;
            height: 30px; }
            .navbar-container .navbar .navbar-collapse .navbar-nav .login-signup.logged-in .user-icon {
              display: none; }
        .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-toggle:after {
          margin-left: 4px;
          vertical-align: 2px;
          border-top: 4px solid #222;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent; }
          @media only screen and (max-width: 991px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-toggle:after {
              position: absolute;
              margin-left: 0;
              right: 30px;
              top: 5px; } }
          @media only screen and (max-width: 575px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-toggle:after {
              right: 15px; } }
        .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-menu-right {
          padding: 0;
          border-radius: 6px;
          overflow: hidden; }
          .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-menu-right .dropdown-item {
            padding: 0; }
            .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-menu-right .dropdown-item a {
              display: block;
              color: #222222;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.1px;
              line-height: 15px;
              text-decoration: none;
              padding: 10px 15px; }
          @media only screen and (max-width: 991px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-menu-right {
              background: transparent;
              border: none;
              padding-left: 30px; } }
          @media only screen and (max-width: 575px) {
            .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown .dropdown-menu-right {
              padding-left: 15px; } }
        @media only screen and (max-width: 991px) {
          .navbar-container .navbar .navbar-collapse .navbar-nav .dropdown.show {
            margin-bottom: 0; } }
        .navbar-container .navbar .navbar-collapse .navbar-nav .profile-dropdown-container {
          display: flex;
          align-items: center;
          margin-left: 25px; }
          .navbar-container .navbar .navbar-collapse .navbar-nav .profile-dropdown-container .nav-link {
            padding: 0; }
            .navbar-container .navbar .navbar-collapse .navbar-nav .profile-dropdown-container .nav-link .profile-img {
              width: 32px;
              height: 32px;
              border-radius: 50%; }
            .navbar-container .navbar .navbar-collapse .navbar-nav .profile-dropdown-container .nav-link span {
              display: inline-block;
              margin-left: 8px;
              color: #222222;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.1px;
              line-height: 15px; }
          .navbar-container .navbar .navbar-collapse .navbar-nav .profile-dropdown-container .dropdown .dropdown-toggle {
            border-radius: 0;
            padding: 0px 0;
            background-color: transparent !important;
            color: #222 !important; }
            .navbar-container .navbar .navbar-collapse .navbar-nav .profile-dropdown-container .dropdown .dropdown-toggle:after {
              margin-left: 5px;
              vertical-align: 5px; }
          .navbar-container .navbar .navbar-collapse .navbar-nav .profile-dropdown-container .dropdown .dropdown-menu {
            padding: 0;
            border-radius: 6px;
            overflow: hidden;
            right: 0 !important;
            left: auto !important;
            min-width: 120px !important;
            background-color: #fff !important; }
            .navbar-container .navbar .navbar-collapse .navbar-nav .profile-dropdown-container .dropdown .dropdown-menu .dropdown-item {
              display: block;
              color: #222222;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.1px;
              line-height: 15px;
              text-decoration: none;
              padding: 10px 15px;
              text-align: left; }
        @media only screen and (max-width: 991px) {
          .navbar-container .navbar .navbar-collapse .navbar-nav {
            min-height: 100%;
            margin-top: 120px; } }
      @media only screen and (max-width: 991px) {
        .navbar-container .navbar .navbar-collapse {
          position: fixed;
          width: 100%;
          height: 100%;
          background: #fff;
          z-index: 991;
          top: -100%;
          left: 0;
          transition: .4s all ease-in-out;
          -webkit-transition: .4s all ease-in-out;
          -moz-transition: .4s all ease-in-out; }
          .navbar-container .navbar .navbar-collapse.show {
            top: 0; } }
  .navbar-container.sticky {
    padding: 7px 0px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09); }
  @media only screen and (max-width: 767px) {
    .navbar-container {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09); }
      .navbar-container[data-open="true"] {
        position: fixed; } }

.navbar-container.non-sticky {
  position: static;
  padding: 10px 0; }
  @media only screen and (max-width: 767px) {
    .navbar-container.non-sticky {
      position: fixed; } }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f9fa !important; }

@media only screen and (max-width: 991px) {
  .mobile-hide {
    display: none !important; } }

body.modal-open {
  overflow: hidden !important;
  padding-right: 0 !important; }

.nav-message {
  margin-left: 20px !important; }

.search-job {
  position: relative; }
  .search-job .search-field {
    padding: 8px 8px !important;
    position: relative;
    min-width: 78px;
    border: 1px solid #45F6A6;
    border-radius: 25px;
    color: #222222;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 15px;
    text-align: center;
    text-decoration: none; }
    .search-job .search-field:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #222222;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      display: none; }
      @media only screen and (max-width: 991px) {
        .search-job .search-field:after {
          right: 30px;
          border-top: 4px solid #222; } }
      @media only screen and (max-width: 575px) {
        .search-job .search-field:after {
          right: 15px; } }
    @media only screen and (max-width: 1199px) {
      .search-job .search-field {
        font-size: 11px; } }
    @media only screen and (max-width: 991px) {
      .search-job .search-field {
        text-align: left;
        border: none;
        border-radius: 0;
        padding: 0 30px !important;
        background-color: transparent;
        color: #222 !important;
        font-size: 14px; } }
    @media only screen and (max-width: 575px) {
      .search-job .search-field {
        padding: 0 15px !important; } }

/*--------------searchjob-sidebar------------*/
.search-sidebar {
  width: 515px;
  height: calc(100vh - 63.78px);
  background-color: #fff;
  top: 98.78px;
  right: 0%;
  position: fixed;
  z-index: 99999;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  display: none; }
  .search-sidebar.active {
    right: 0;
    display: block;
    z-index: 999; }
  .search-sidebar.fixed {
    height: calc(100vh - 57.78px);
    top: 57.78px; }
  .search-sidebar .search-job {
    position: relative;
    z-index: 999;
    overflow: auto;
    height: 100%;
    background-color: #fff; }
    .search-sidebar .search-job .search-heading {
      color: #222222;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      padding: 20px;
      background-color: #F9F9F9;
      margin-bottom: 0;
      position: relative; }
      .search-sidebar .search-job .search-heading .back-btn {
        display: none;
        color: #45F6A6;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px; }
        @media only screen and (max-width: 991px) {
          .search-sidebar .search-job .search-heading .back-btn {
            display: block;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%); } }
    .search-sidebar .search-job .accordian {
      height: calc(100vh - 185.39px) !important;
      overflow: unset !important;
      overflow-y: auto !important; }
      .search-sidebar .search-job .accordian .accordion {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #97979726;
        border-top: 1px solid #97979726; }
        .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__heading .accordion__button {
          position: relative;
          color: #222222;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 45px;
          background: transparent;
          padding: 0px 20px;
          border-top: 1px solid #97979726; }
          .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__heading .accordion__button .counter {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #3cf39b;
            line-height: 20px;
            color: #222222;
            font-size: 12px;
            font-weight: 500;
            display: inline-block;
            letter-spacing: 0;
            text-align: center; }
          .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__heading .accordion__button:before {
            position: absolute;
            top: 50%;
            right: 20px;
            margin-right: 0;
            color: #9a9a9a;
            transform: translateY(-50%) rotate(45deg);
            transition: .2s all ease-in-out; }
        .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel {
          padding: 15px 20px;
          padding-top: 0; }
          .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .search-input {
            position: relative;
            margin-bottom: 10px; }
            .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .search-input input {
              border: 0.5px solid #EAEAEA;
              border-radius: 19px;
              background-color: #F8F7F7;
              width: 100%;
              /* opacity: 0.2; */
              color: #000000;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 18px;
              padding: 7px 17px; }
              .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .search-input input::-webkit-input-placeholder {
                color: rgba(0, 0, 0, 0.2); }
              .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .search-input input::-moz-input-placeholder {
                color: rgba(0, 0, 0, 0.2); }
            .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .search-input .search-icon {
              position: absolute;
              top: 50%;
              right: 17px;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%); }
              .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .search-input .search-icon svg {
                color: rgba(0, 0, 0, 0.2); }
          .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .tags-container {
            max-height: 200px;
            overflow-y: scroll;
            /* width */
            /* Track */
            /* Handle */
            /* Handle on hover */ }
            .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .tags-container .tag {
              border-radius: 0px;
              background-color: #fff;
              padding: 0;
              margin-right: 8px;
              margin-bottom: 10px; }
              .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .tags-container .tag > div {
                color: #222222;
                font-size: 12px;
                font-weight: 500 !important;
                letter-spacing: -0.1px;
                line-height: 16px;
                text-align: center;
                border-radius: 20px;
                background-color: #F5F5F5;
                padding: 9px 14px; }
                .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .tags-container .tag > div.active {
                  background-color: #45F6A6 !important;
                  font-size: 13px !important;
                  font-weight: 500 !important;
                  letter-spacing: -0.1px !important;
                  line-height: 16px !important;
                  padding: 9px 14px !important; }
            .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .tags-container::-webkit-scrollbar {
              width: 5px; }
            .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .tags-container::-webkit-scrollbar-track {
              box-shadow: inset 0 0 1px #ddd;
              border-radius: 10px; }
            .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .tags-container::-webkit-scrollbar-thumb {
              background: #ccc;
              border-radius: 10px; }
            .search-sidebar .search-job .accordian .accordion .job-function .accordion__item .accordion__panel .tags-container::-webkit-scrollbar-thumb:hover {
              background: #eee; }
        .search-sidebar .search-job .accordian .accordion .job-function:nth-child(1) .accordion__item .accordion__heading .accordion__button {
          border-top: none; }
    .search-sidebar .search-job .searchbar-btns {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      position: fixed;
      width: 515px;
      max-width: 515px;
      bottom: 0;
      right: 0;
      margin-bottom: 0px;
      background: #fff; }
      .search-sidebar .search-job .searchbar-btns .advance-search {
        color: #45F6A6;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px; }
      .search-sidebar .search-job .searchbar-btns .search-btn {
        border-radius: 25px;
        background-color: #45F6A6;
        color: #2A4673;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        padding: 10px 20px;
        border: none;
        outline: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        text-transform: capitalize;
        margin-left: 25px; }
        @media only screen and (max-width: 767px) {
          .search-sidebar .search-job .searchbar-btns .search-btn {
            margin-left: 15px;
            font-size: 10px;
            padding: 7px 10px; } }
      @media only screen and (max-width: 991px) {
        .search-sidebar .search-job .searchbar-btns {
          bottom: 0px;
          width: 100%;
          max-width: 100%; } }
  .search-sidebar .sidebar-overlay {
    width: 100%;
    position: fixed;
    height: 100vh;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: -1; }
  @media only screen and (max-width: 991px) {
    .search-sidebar {
      width: 100%;
      height: 100vh;
      /* top: 0; */
      z-index: 99999999999999;
      position: fixed; } }

/*--------------searchjob-sidebar------------*/
.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: translateY(-50%) rotate(-135deg) !important; }

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.75) !important; }

.ReactModal__Content.ReactModal__Content--after-open {
  z-index: 9999999999;
  overflow: unset !important; }
  .ReactModal__Content.ReactModal__Content--after-open > div {
    cursor: pointer;
    margin-bottom: 5px; }
  .ReactModal__Content.ReactModal__Content--after-open .close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #45F6A6;
    display: flex;
    align-items: center;
    justify-content: center; }
    .ReactModal__Content.ReactModal__Content--after-open .close-icon img {
      width: 12px; }
  @media only screen and (max-width: 480px) {
    .ReactModal__Content.ReactModal__Content--after-open {
      margin-right: -40% !important; } }

html.open-right-sidebar body.modal-open .navbar-container .navbar {
  z-index: 999; }

@media only screen and (max-width: 991px) {
  html.open-right-sidebar body.modal-open .navbar-container .top-bar-message {
    display: none; } }

body.modal-open .navbar-container .navbar {
  z-index: 999; }

@media only screen and (max-width: 767px) {
  body.rGoogleCaptcha .navbar-container {
    display: none; } }

.top-bar-message {
  background: #253f69;
  padding: 10px 0;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px; }
  .top-bar-message p {
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 24px; }

@media (max-width: 991px) {
  .profile-dropdown-container .title.dropdown {
    display: none !important; }
  .collapse.show.navbar-collapse .navbar-nav {
    align-items: flex-start; }
    .collapse.show.navbar-collapse .navbar-nav .login-signup.logged-in {
      display: none; }
    .collapse.show.navbar-collapse .navbar-nav .profile-dropdown-container.mobile-hide {
      display: block !important;
      width: 100% !important;
      margin: 0; }
      .collapse.show.navbar-collapse .navbar-nav .profile-dropdown-container.mobile-hide > .nav-link {
        display: none; }
      .collapse.show.navbar-collapse .navbar-nav .profile-dropdown-container.mobile-hide .dropdown {
        display: block !important;
        width: 100%; }
      .collapse.show.navbar-collapse .navbar-nav .profile-dropdown-container.mobile-hide .dropdown-toggle {
        display: none; }
      .collapse.show.navbar-collapse .navbar-nav .profile-dropdown-container.mobile-hide .dropdown-menu {
        display: block !important;
        box-shadow: none !important;
        transform: none !important;
        width: 100% !important;
        min-width: 100% !important; }
        .collapse.show.navbar-collapse .navbar-nav .profile-dropdown-container.mobile-hide .dropdown-menu .dropdown-item {
          width: 100% !important;
          padding-left: 30px !important;
          padding-bottom: 20px !important;
          padding-top: 0px !important;
          color: #222 !important;
          font-size: 15px !important;
          font-weight: 500 !important; } }
        @media (max-width: 991px) and (max-width: 575px) {
          .collapse.show.navbar-collapse .navbar-nav .profile-dropdown-container.mobile-hide .dropdown-menu .dropdown-item {
            padding-left: 15px !important; } }
